import { SET_FLOW_METHOD_LIST } from '../types';
import { SET_FLOW_METHOD_TRANSFORMATION_LIST } from '../types';
import { SET_FLOW_METHOD_CONDITION_LIST } from '../types';
import { SET_FLOW_METHOD_FIELD_LIST } from '../types';

const initialState = {
  flowMethodList: [],
};

const flowMethodList = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLOW_METHOD_LIST:
      return {
        ...state,
        flowMethodList: action.payload,
      };
    case SET_FLOW_METHOD_TRANSFORMATION_LIST:
      return {
        ...state,
        transformationList: action.payload,
      };
    case SET_FLOW_METHOD_CONDITION_LIST:
      return {
        ...state,
        conditionList: action.payload,
      };
    case SET_FLOW_METHOD_FIELD_LIST:
      return {
        ...state,
        fieldList: action.payload,
      };
    default:
      return state;
  }
};

export default flowMethodList;
