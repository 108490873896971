import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider, Box, Container } from '@mui/material';

import AppRouter from 'Src/AppRouter';
import Header from 'Shared/Header';
import Footer from 'Shared/Footer';
import Loader from 'Shared/Loader';
import { ErrorBoundary } from 'Shared/error-boundary/ErrorBoundary';
import { SnackbarProvider } from 'notistack';
import { theme } from 'Utils/theme';

function App() {
    const isLoading = useSelector((state) => state.loading.isLoading);

    return (
        <div className="App">
            <ErrorBoundary>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider maxSnack={3} autoHideDuration={7000}>
                        {isLoading && <Loader/>}
                        <Header/>
                        <Box
                            component="main"
                            sx={{
                                display: 'flex',
                                flex: '1',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                            }}
                        >
                            <Container maxWidth={false}
                                       sx={{height: '100%', paddingTop: '30px', paddingBottom: '30px'}}>
                                <AppRouter/>
                            </Container>
                        </Box>
                        <Footer/>
                    </SnackbarProvider>
                </ThemeProvider>
            </ErrorBoundary>
        </div>
    );
}

export default App;
