import React from 'react';
import {useForm} from 'react-hook-form';
import {useSelector, useDispatch} from 'react-redux';
import {setLoading} from 'Actions/loading';
import {
    Box,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    FormHelperText,
    IconButton,
} from '@mui/material';
import {Transition} from 'Shared/Transition';
import {CustomSnackbar} from 'Shared/CustomSnackbar';
import {useSnackbar} from 'notistack';
import {CloseIcon} from 'Shared/Icons';
import http from 'Utils/http';

const callTypeList = ['GET', 'POST', 'DELETE', 'PATH', 'PUT', 'SKIP'];

export const AddMethodsModal = ({visible, onClose, setNeedRefresh}) => {
    
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const apiList = useSelector((state) => state.APIs.APIs);

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const onSubmit = (data) => {
        dispatch(setLoading(true));
        http
            .post(`/integration/${data.integration_id}/method/create`, data)
            .then((res) => {
                enqueueSnackbar('Your method has been saved', {action: CustomSnackbar, variant: 'success'});
                onClose();
            })
            .catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {action: CustomSnackbar, variant: 'error'});
            })
            .finally(() => {
                setNeedRefresh(true);
                dispatch(setLoading(false));
            });
    };

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '50vw',
                    backgroundColor: '#FAFEFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '16px',
                    boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                },
            }}
            //
            open={visible}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                }}
            >
                <IconButton size="large" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="large"/>
                </IconButton>
            </Box>
            <DialogTitle
                color="primary.blackText"
                sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                }}
            >
                Add new method
            </DialogTitle>

            <DialogContent
                sx={{
                    display: 'flex',
                    padding: '35px 30px 20px 25px',
                    overflow: 'hidden',
                }}
            >
                <Grid container direction="row">
                    <Grid
                        item
                        xs={12}
                        sx={{
                            height: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            sx={{
                                height: 'inherit',
                            }}
                        >
                            <Box
                                className="scroll-container"
                                sx={{
                                    paddingTop: '10px',
                                    paddingRight: '15px',
                                    overflowY: 'auto',
                                    height: 'inherit',
                                }}
                            >
                                <FormControl
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    error={!!errors.integration_id}
                                >
                                    <InputLabel>Integration</InputLabel>
                                    <Select
                                        defaultValue=""
                                        label="Integration"
                                        {...register('integration_id', {
                                            required: true,
                                        })}
                                    >
                                        {apiList.map((item) => {
                                            return (
                                                <MenuItem key={`${item.id}_integration_id`} value={item.id}
                                                          disabled={item?.name === 'PORT'}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.integration_id ?
                                        <FormHelperText>This is a required field</FormHelperText> : ''}
                                </FormControl>
                                <TextField
                                    error={!!errors?.name}
                                    helperText={errors?.name ? 'This is a required field' : ''}
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    label="Method Name"
                                    {...register('name', {required: true})}
                                />
                                <TextField
                                    error={!!errors?.url}
                                    helperText={errors?.url ? 'This is a required field' : ''}
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    label="URL"
                                    {...register('url', {required: true})}
                                />
                                <FormControl
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    error={!!errors.method}
                                >
                                    <InputLabel>CallType</InputLabel>
                                    <Select
                                        defaultValue=""
                                        label="CallType"
                                        {...register('method', {
                                            required: true,
                                        })}
                                    >
                                        {callTypeList.map((item) => {
                                            return (
                                                <MenuItem key={`${item}_method`} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.method ? <FormHelperText>This is a required field</FormHelperText> : ''}
                                </FormControl>
                                <Button
                                    sx={{
                                        padding: '16px 26px',
                                        fontSize: '16px',
                                        margin: '0 auto 8px auto',
                                        width: '100%',
                                        borderRadius: '4px',
                                    }}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Add Method
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
