import {
  SET_FLOW_METHOD_LIST,
  SET_FLOW_METHOD_TRANSFORMATION_LIST,
  SET_FLOW_METHOD_CONDITION_LIST,
  SET_FLOW_METHOD_FIELD_LIST,
} from '../types';

export const setFlowMethodList = (data) => (dispatch) => {
  dispatch({
    type: SET_FLOW_METHOD_LIST,
    payload: data,
  });
};

export const setFlowMethodTransformationList = (data) => (dispatch) => {
  dispatch({
    type: SET_FLOW_METHOD_TRANSFORMATION_LIST,
    payload: data,
  });
};

export const setFlowMethodConditionList = (data) => (dispatch) => {
  dispatch({
    type: SET_FLOW_METHOD_CONDITION_LIST,
    payload: data,
  });
};

export const setFlowMethodFieldList = (data) => (dispatch) => {
  dispatch({
    type: SET_FLOW_METHOD_FIELD_LIST,
    payload: data,
  });
};
