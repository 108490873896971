import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid, Dialog, DialogContent, IconButton } from '@mui/material';
import { Transition } from 'Shared/Transition';
import { CloseIcon } from 'Shared/Icons';
import { SelectActionsFinalizateFlowModal } from 'Shared/flows/finalizate-flow-modal/SelectActionsFinalizateFlowModal';
import { SendEmail } from 'Shared/flows/finalizate-flow-modal/SendEmail';
import { SaveToFile } from 'Shared/flows/finalizate-flow-modal/SaveToFile';
import { ShowResult } from 'Shared/flows/finalizate-flow-modal/ShowResult';
import { useIsFirstRender } from 'Hooks/useIsFirstRender';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import http from 'Utils/http';

export const CompleteFlowModal = ({ needRefresh, setNeedRefresh, visible, onClose }) => {
    const isFirst = useIsFirstRender();
    const { createUrl } = useCreateUrl();
    const { flow_id } = useParams();

    const flow_id_redux = useSelector((state) => state.flow.flow?.id);

    //change state
    const [stateModal, setStateModal] = useState(null);
    const [stateModalId, setStateModalId] = useState(null);
    const [outputs, setOutputs] = useState([]);

    useEffect(() => {
        if (+flow_id_redux !== +flow_id) {
            return;
        }
        if (!needRefresh) {
            return;
        }
        if (!flow_id_redux) {
            return;
        }

        if (sessionStorage.getItem('outputs') && isFirst) {
            const outputs = JSON.parse(sessionStorage.getItem('outputs'));
            setOutputs([...outputs]);
            return;
        }

        if (needRefresh) {
            http.get(createUrl(`/output/${flow_id_redux}/fields`)).then((res) => {
                setOutputs(res.data.fields);
                sessionStorage.setItem('outputs', JSON.stringify(res.data.fields));
            }).catch((err) => {
                console.log(err, 'err');
            }).finally(() => {
                setNeedRefresh(false);
            });
        }
    }, [flow_id_redux, needRefresh, flow_id, isFirst, createUrl, setNeedRefresh]);


    const renderModalContent = () => {
        switch (stateModal) {
            case 'saveToFile':
                return <SaveToFile outputs={outputs} setNeedRefresh={setNeedRefresh} operationId={stateModalId} />;
            case 'sendEmail':
                return <SendEmail outputs={outputs} setNeedRefresh={setNeedRefresh} operationId={stateModalId} />;
            case 'showResult':
                return <ShowResult outputs={outputs} setNeedRefresh={setNeedRefresh} operationId={stateModalId} />;
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={visible}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '75vw',
                    minHeight: '70vh',
                    backgroundColor: '#FAFEFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '16px',
                    boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                },
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                }}
            >
                <IconButton size="large" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="large"/>
                </IconButton>
            </Box>

            <DialogContent
                sx={{
                    display: 'flex',
                    padding: '35px 30px 20px 25px',
                    overflow: 'hidden',
                }}
            >
                <Grid container direction="row">
                    {/* left */}
                    <SelectActionsFinalizateFlowModal setStateModal={setStateModal} setStateModalId={setStateModalId}/>

                    {/* right */}
                    <Grid
                        item
                        xs={8}
                        sx={{
                            height: '100%',
                            paddingLeft: '25px',
                            borderRadius: '10px',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                padding: '10px 5px 20px 20px',
                                background: '#F2F7F9',
                                border: '1px solid rgba(148, 157, 176, 0.25)',
                                borderRadius: '10px',
                                height: 'inherit',
                            }}
                        >
                            <Box
                                className="scroll-container"
                                sx={{
                                    paddingTop: '10px',
                                    paddingRight: '15px',
                                    overflowY: 'auto',
                                    height: 'inherit',
                                }}
                            >
                                {renderModalContent()}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
