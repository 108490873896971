import http from 'Utils/http';
import { SET_CURRENT_USER } from '../types';
import { refreshAllState } from 'Actions/refresh';
import { ROUTES } from 'Constants/routes';

export const login = (userData, navigate, navigateTo) => (dispatch) => {
    try {
        localStorage.setItem('token', userData.token);
        localStorage.setItem('currentUser', JSON.stringify(userData));
        http.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
        dispatch(setCurrentUser({...userData}));
        if (navigate) {
            navigate(navigateTo);
        }
    } catch (error) {
        console.error('Login error:', error);
    }
};

export const logout = (navigate) => (dispatch) => {
    try {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(refreshAllState({}));
        http.defaults.headers.common['Authorization'] = null;
        navigate(ROUTES.HOME);
    } catch (error) {
        console.error('Logout error:', error);
    }
};

export const setCurrentUser = (user) => ({
    type: SET_CURRENT_USER,
    payload: user,
});
