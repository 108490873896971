import * as React from 'react';
import { AppBar, Toolbar, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'Constants/routes';

const { version } = require('../../../package.json');

const Footer = () => {
    const year = new Date().getFullYear();
    const navigate = useNavigate();

    if (!localStorage.getItem('version') || localStorage.getItem('version') !== version) {
        console.warn('Version APP not correct need updated state:', version);
        if (version) {
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(() => {
                localStorage.setItem('version', version);
                navigate(ROUTES.HOME);
            }, 500);
        }
    }

    return (
        <AppBar position="static" component="footer">
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Typography
                        sx={{
                            marginLeft: 'auto',
                            fontWeight: 600,
                        }}
                    >
                        Version {version}
                        <br/>© Fintegrate, 2022 - {year}
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Footer;
