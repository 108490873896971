import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from 'Shared/Transition';

export const AlertDialog = ({
                                visible = false,
                                onClose,
                                onAgree,
                                title = 'Confirm your action',
                                text = 'Are you sure you want to exit?',
                                noButton = null,
                                yesButton = null,
                                noButtonText = 'No',
                                yesButtonText = 'Yes'
                            }) => {
    return (
        <Dialog
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'flex-start',
                    paddingTop: '150px',
                },
                '& .MuiDialog-paper': {
                    minWidth: '450px',
                },
            }}
            open={visible}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            <DialogTitle id="alert-dialog-title" color="primary.blackText">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {noButton ? noButton : <Button onClick={onClose}>{noButtonText}</Button>}
                {yesButton ? yesButton : <Button onClick={onAgree} autoFocus>{yesButtonText}</Button>}
            </DialogActions>
        </Dialog>
    );
};
