import React, { useState, useEffect, useMemo, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { Box, Button, Typography } from '@mui/material';
import { SelectApiField } from 'Shared/flows/finalizate-flow-modal/SelectApiField';
import { PredefinedSelectApiField } from 'Shared/flows/finalizate-flow-modal/PredefinedSelectApiField';
import { useSnackbar } from 'notistack';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import http from 'Utils/http';

export const ShowResult = ({ outputs, setNeedRefresh, operationId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const apiList = useSelector((state) => state.APIs.APIs);
    const flow_id = useSelector((state) => state.flow.flow?.id);
    const flowMethodList = useSelector((state) => state.flowMethodList.flowMethodList);

    const [outputPortLastOrder, setOutputPortLastOrder] = useState([]);

    useEffect(() => {
        if (flow_id) {
            http.get(`/output/actions/${flow_id}`).then((res) => {
                const sortByOrder = res.data.actions.sort((a, b) => (a.order > b.order ? 1 : -1));
                setOutputPortLastOrder(sortByOrder[sortByOrder.length - 1]);
            }).catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {
                    action: CustomSnackbar,
                    variant: 'error'
                });
            });
        }
    }, [flow_id, enqueueSnackbar]);

    const saveShowResult = () => {
        if (outputPortLastOrder) {
            dispatch(setLoading(true));

            const params = {
                id: outputPortLastOrder.id,
                modificator: null,
                operation_id: operationId,
            };

            http.post(`/action/${outputPortLastOrder.id}/update`, params).then(() => {
                enqueueSnackbar('Your modification has been saved', {
                    action: CustomSnackbar,
                    variant: 'success'
                });
            }).catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {
                    action: CustomSnackbar,
                    variant: 'error'
                });
            }).finally(() => {
                dispatch(setLoading(false));
            });
        }

        if (!outputPortLastOrder) {
            dispatch(setLoading(true));

            const params = {
                flow_id: flow_id,
                modificator: null,
                operation_id: operationId,
                type: 'for_output_port',
            };

            http.post('/action/create', params).then(() => {
                enqueueSnackbar('Your modification has been saved', {
                    action: CustomSnackbar,
                    variant: 'success'
                });
            }).catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {
                    action: CustomSnackbar,
                    variant: 'error'
                });
            }).finally(() => {
                dispatch(setLoading(false));
            });
        }
    };

    const filteredOutputs = useMemo(() => {
        return outputs.filter(output => {
            return flowMethodList.some(flowMethod => flowMethod.method.id === output.method_id);
        });
    }, [outputs, flowMethodList]);

    const mappedOutputs = useMemo(() => {
        return filteredOutputs.map(output => {
            const foundFlowMethod = flowMethodList.find(flowMethod => flowMethod.method.id === output.method_id);
            if (foundFlowMethod) {
                return (
                    <PredefinedSelectApiField
                        key={output.delete_id}
                        output={output}
                        setNeedRefresh={setNeedRefresh}
                        user_flow_id={flow_id}
                        apiList={apiList}
                        flowMethodList={flowMethodList}
                    />
                );
            }
            return null;
        });
    }, [filteredOutputs, flowMethodList, setNeedRefresh, flow_id, apiList]);

    return (
        <Box>
            <Typography
                sx={{
                    marginBottom: '15px',
                    fontWeight: '700',
                    letterSpacing: '0.1em',
                    textTransform: 'uppercase',
                    color: 'rgba(65, 77, 101, 0.85)',
                }}
            >
                SHOW RESULT
            </Typography>

            <Button sx={{marginBottom: '20px'}} variant="contained" onClick={saveShowResult}>
                Save
            </Button>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '15px 12px',
                    marginBottom: '25px',
                    background: 'rgba(65, 77, 101, 0.05)',
                    borderRadius: '6px',
                }}
            >
                <Typography
                    sx={{
                        marginBottom: '16px',
                        fontWeight: '500',
                        color: '#949DB0',
                    }}
                >
                    API field to be sent
                </Typography>

                {/* Predefined list */}
                <Suspense fallback={<div>Loading...</div>}>
                    {mappedOutputs}
                </Suspense>

                <SelectApiField setNeedRefresh={setNeedRefresh}/>
            </Box>
        </Box>
    );
};
