import axios from 'axios';
import { ROUTES } from "../constants/routes";

// Instance without 401 error handling
export const httpWithout401 = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '/api',
});

// Instance with 401 error handling
const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '/api',
});

if (localStorage.getItem('token')) {
    http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
}

// Add an interceptor to handle 401 errors
const interceptor = http.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Handle 401 error (e.g., redirect to login)
            window.location.href = `${window.location.origin}${ROUTES.LOGIN}`;
        }
        return Promise.reject(error);
    }
);

http.interceptors.response.eject(interceptor);

export default http;
