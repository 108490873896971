import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomBlackSwitch = styled(Switch)(({ theme }) => ({
  margin: '0 6px',
  width: 32,
  height: 20,
  padding: '0px',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(10px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '2px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'transparent',
        border: '1px solid #949DB0',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    color: '#414D65',
    boxShadow: '0px 1.88235px 5.01961px rgba(0, 0, 0, 0.15), 0px 1.88235px 0.627451px rgba(0, 0, 0, 0.06)',
    width: 16,
    height: 16,
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: '9px',
    opacity: 1,
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    border: '1px solid #949DB0',
  },
}));
