import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import http from 'Utils/http';

export const SelectActionsFinalizateFlowModal = ({ setStateModal, setStateModalId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const flow_id = useSelector((state) => state.flow.flow?.id);
    const operatorsListOutput = useSelector((state) => state.operators.operators.output);

    const mappedOperatorsListOutput = operatorsListOutput.map((item) => {
        return {
            ...item,
            ...{
                title: `${item.operation.charAt(0).toUpperCase()}${item.operation.replace(/([a-z])([A-Z])/g, '$1 $2')
                    .slice(1)}`,
            },
        };
    }).filter(item => item.operation !== 'callApi');

    const {setValue, watch, control} = useForm({});

    useEffect(() => {
        const subscription = watch((value, {name}) => {
            if (name === 'action') {
                setStateModal(value.action);

                let stateModalOperation = operatorsListOutput?.find((item) => {
                    return item.operation === value.action;
                });

                if (stateModalOperation) {
                    setStateModalId(stateModalOperation?.id);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, operatorsListOutput, setStateModal, setStateModalId]);

    useEffect(() => {
        if (flow_id) {
            http.get(`/output/actions/${flow_id}`).then((res) => {
                if (res.data?.actions) {
                    const sortByOrder = res.data.actions.sort((a, b) => (a.order > b.order ? 1 : -1));
                    if (sortByOrder[sortByOrder.length - 1]) {
                        setValue('action', sortByOrder[sortByOrder.length - 1]?.operation?.operation);
                    } else {
                        setValue('action', 'showResult');
                    }
                }
            }).catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {
                    action: CustomSnackbar,
                    variant: 'error'
                });
            });
        }
    }, [flow_id, enqueueSnackbar, setValue]);

    return (
        <>
            <Grid item xs={4}>
                <Typography
                    variant="h2"
                    sx={{
                        marginBottom: '5px',
                        textAlign: 'center',
                        fontWeight: 500,
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: '#3B465C',
                    }}
                >
                    Finalize Flow
                </Typography>

                <Typography
                    sx={{
                        textAlign: 'center',
                        marginBottom: '35px',
                        color: '#949DB0',
                    }}
                >
                    Please select what action to be executed after the flow is finished (optional)
                </Typography>

                <FormControl
                    sx={{
                        width: '100%',
                        marginBottom: '40px',
                    }}
                >
                    <InputLabel>Select Action</InputLabel>
                    <Controller
                        name="action"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        defaultValue={'showResult'}
                        render={({field}) => (
                            <Select {...field} fullWidth label="Select Action">
                                {mappedOperatorsListOutput && mappedOperatorsListOutput.map((item) => (
                                    <MenuItem key={item.id} value={item.operation}>
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>
        </>
    );
};
