import React from 'react';
import { Box, Button } from '@mui/material';

export const UserIntegrationCreds = ({ item, unlinkIntegration }) => {
  const testConnection = () => {
    console.log('testConnection');
  };

  return (
    <Box>
      <Button
        onClick={testConnection}
        sx={{
          marginRight: '20px',
        }}
        color="primary"
        variant="outlined"
      >
        Test Connection
      </Button>
    </Box>
  );
};
