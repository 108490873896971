import React, {useState, useEffect, useCallback} from 'react';
import http from 'Utils/http';
import { useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { Box, Grid, Typography } from '@mui/material';
import { UserIntegrationItem } from 'Shared/integrations/UserIntegrationItem';
import { UserIntegrationItemPreDefined } from 'Shared/integrations/UserIntegrationItemPreDefined';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Logo_PNG from 'Assets/img/logo_png.png';

const IntegrationsListPage = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [userIntegrationsList, setUserIntegrationsList] = useState([]);
    const [integrationsList, setIntegrationsList] = useState([]);

    const getListsIntegrations = useCallback(() => {
        dispatch(setLoading(true));
        axios.all([
            http.get('/integrations'),
            http.get('/user/integration/list')
        ]).then(
            axios.spread((firstResponse, secondResponse) => {
                setIntegrationsList(firstResponse.data.integrations);
                setUserIntegrationsList(secondResponse.data.integrations);
                dispatch(setLoading(false));
            })
        ).catch(() => {
            dispatch(setLoading(false));
        });
    }, [dispatch]);

    const getMyIntegrationList = () => {
        http.get('/user/integration/list').then((res) => {
            setUserIntegrationsList(res.data.integrations);
        }).catch(() => {
        });
    };

    const linkIntegrationToUser = (item) => {
        dispatch(setLoading(true));
        http.post(`/user/integration/${item.id}/link`).then(() => {
            getMyIntegrationList();
            dispatch(setLoading(false));
            enqueueSnackbar('Integration was added to your list', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    const unlinkIntegration = (item) => {
        dispatch(setLoading(true));
        http.delete(`/user/integration/${item.id}/delete`).then((res) => {
            getMyIntegrationList();
            dispatch(setLoading(false));
            enqueueSnackbar('Integration was unlinked', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    useEffect(() => {
        getListsIntegrations();
    }, [getListsIntegrations]);

    return (
        <>
            <Grid container sx={{height: '100%'}}>
                <Grid item xs={3} sx={{paddingRight: '20px'}}>
                    <Box
                        sx={{
                            height: '100%',
                            backgroundColor: '#f9f7f5',
                            border: '1px solid #FFFFFF',
                            boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                            borderRadius: '16px',
                            overflow: 'hidden',
                        }}
                    >
                        <img style={{width: '100%'}} src={Logo_PNG} alt=""/>
                    </Box>
                </Grid>

                <Grid item xs={9}>
                    <Box
                        sx={{
                            padding: '30px 25px 25px 25px',
                            height: '100%',
                            backgroundColor: '#FAFEFF',
                            border: '1px solid #FFFFFF',
                            boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                            borderRadius: '16px',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography
                            variant="h2"
                            color="primary.blackText"
                            sx={{
                                marginBottom: '20px',
                                textAlign: 'center',
                                fontWeight: 500,
                                fontSize: '20px',
                                lineHeight: '24px',
                            }}
                        >
                            My integrations list:
                        </Typography>
                        {!userIntegrationsList.length && (
                            <Typography
                                sx={{
                                    marginBottom: '20px',
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    color: '#D76A6A',
                                }}
                            >
                                Your integrations list is empty
                            </Typography>
                        )}

                        {userIntegrationsList.map(({ name, ...rest }) => (
                            <UserIntegrationItem
                                key={name}
                                item={{ name, ...rest }}
                                unlinkIntegration={() => unlinkIntegration({ name, ...rest })}
                            />
                        ))}

                        <Typography
                            variant="h2"
                            color="primary.blackText"
                            sx={{
                                marginTop: '46px',
                                marginBottom: '20px',
                                textAlign: 'center',
                                fontWeight: 500,
                                fontSize: '20px',
                                lineHeight: '24px',
                            }}
                        >
                            Fintegrate Library of Integrations:
                        </Typography>

                        {integrationsList.map(({ name, ...rest }) => (
                            <UserIntegrationItemPreDefined
                                key={name}
                                item={{ name, ...rest }}
                                linkIntegrationToUser={() => linkIntegrationToUser({ name, ...rest })}
                            />
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default IntegrationsListPage;
