export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  FORGOT_PASSWORD: '/forgot-password',
  PASSWORD_RESET: '/password-reset',
  MY_ACCOUNT: '/my-account',
  INTEGRATIONS: '/integrations',
  ADD_INTEGRATION: '/add-integration',
  FLOWS: '/flows',
  FLOW_BY_ID: '/flow/:flow_id',
  FLOW_BY_ID_WITH_CURRENT_USER: '/flow/:flow_id/current_user/:current_user_id',
  LOGGING: '/logging',
  METHODS: '/methods',
  METHODS_PARAMS: '/methods-params',
  USERS: '/users',
  USERS_BY_ID: '/users/:id',
  ADD_USER: '/add-user',
  ALL: '*',
  NOT_FOUND: '/404',
};
