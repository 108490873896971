import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  IconButton,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { AlertDialog } from 'Shared/AlertDialog';
import FlowsGraph from 'Shared/flows/FlowsGraph';

export const AdminFlowsItemV2 = ({ item, deleteFlow, changeStatusFlow }) => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const deleteText = 'Are you sure you want to delete flow in your list?';
  const changeText = 'Are you sure you want to change status flow?';
  const [modalText, setModalText] = useState('Are you sure you want to change status flow?');
  const [modalAction, setModalAction] = useState('change');

  const toFlow = (event, item) => {
    event.preventDefault();
    navigate(`/flow/${item.id}/current_user/${item.current_user_id}`);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (event.target.classList.contains('PrivateSwitchBase-input')) {
      return;
    }
    if (event.target.classList.contains('MuiButtonBase-root')) {
      return;
    }
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeSwitch = (e) => {
    e.preventDefault();
    setModalText(changeText);
    setModalAction('change');
    setIsActiveModal(true);
  };

  const handleDelete = (e, id, status) => {
    e.stopPropagation();
    setModalText(deleteText);
    setModalAction('delete');
    setIsActiveModal(true);
  };

  const [isActiveModal, setIsActiveModal] = useState(false);
  const onClose = () => setIsActiveModal(false);
  const onAgree = (e) => {
    setIsActiveModal(false);
    if (modalAction === 'delete') {
      deleteFlow(item);
    }
    if (modalAction === 'change') {
      const foo = {
        ...item,
        status: item.status === 'active' ? 'disabled' : 'active',
      };
      changeStatusFlow(foo);
    }
  };

  return (
    <>
      <AlertDialog visible={isActiveModal} onClose={onClose} onAgree={onAgree} text={modalText} />

      <Accordion
        sx={{
          marginBottom: '20px',
        }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          sx={{
            backgroundColor: '#F2F5F8',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
            },
          }}
          expandIcon={
            <IconButton
              onClick={(e) => {
                toFlow(e, item);
              }}
            >
              <SettingsIcon />
            </IconButton>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            variant="h6"
            sx={{
              color: '#3B465C',
              marginRight: '20px',
              flexShrink: 0,
            }}
          >
            {item?.name}
          </Typography>

          <Switch
            sx={{
              marginLeft: 'auto',
            }}
            checked={item.status === 'active'}
            color="primary"
            onClick={(e) => handleChangeSwitch(e)}
          />

          <IconButton
            sx={{
              marginRight: '14px',
              color: '#f44336',
            }}
            onClick={(e) => {
              handleDelete(e, item.id, item.status);
            }}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails>
          {expanded ? (<FlowsGraph item={item}/>) : null}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
