import { SET_FLOW_METHOD_RESPONSE } from '../types';

const initialState = {
  flowMethodResponse: {},
};

const flowMethodResponse  = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLOW_METHOD_RESPONSE:
      return {
        ...state,
        flowMethodResponse: action.payload,
      };
    default:
      return state;
  }
};

export default flowMethodResponse;
