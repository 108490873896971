import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import { AddIcon } from 'Shared/Icons';

export const UserPreDefinedFlowsItem = ({ item, linkFlowToUser }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    if (event.target.classList.contains('PrivateSwitchBase-input')) {
      return;
    }
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      sx={{
        marginBottom: '20px',
      }}
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary
        sx={{
          backgroundColor: '#F2F5F8',
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
          },
        }}
        expandIcon={
            <Tooltip title={
                <span style={{fontSize: '1rem', lineHeight: '120%'}}>Details</span>
            } arrow>
                <SettingsIcon/>
            </Tooltip>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography
          variant="h6"
          sx={{
            color: '#3B465C',
            marginRight: '20px',
            flexShrink: 0,
          }}
        >
          {item?.name}
          {/* FLOW NAME */}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Button
          sx={{
            marginTop: '8px',
          }}
          onClick={linkFlowToUser}
          startIcon={<AddIcon />}
          variant="outlined"
        >
          Add To My Active Flows
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};
