import React, { useState } from 'react';
import http from 'Utils/http';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { Transition } from 'Shared/Transition';
import { CustomSnackbar } from 'Shared/CustomSnackbar';

function BeautifyJson({ jsonStr }) {
    const beautifiedJson = JSON.stringify(JSON.parse(jsonStr), null, 4);

    return (
        <pre style={{ overflow: 'auto', maxHeight: '300px' }}>{beautifiedJson}</pre>
    );
}

const FlowRunFlow = ({id, visible, setVisible}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [response, setResponse] = useState('');

    const onClose = () => {
        setVisible(false);
        setResponse('');
    };

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({});

    const findErrorKey = (obj) => {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                const result = findErrorKey(obj[key]);
                if (result) return result;
            } else {
                if ((key === 'error' || key === 'message' || key === 'error_description') && typeof obj[key] === 'string') {
                    return obj[key];
                } else if (key === 'error' && typeof obj[key] === 'object') {
                    const result = findErrorKey(obj[key]);
                    if (result) return result;
                }
            }
        }
        return null;
    };

    const onSubmit = async (data) => {
        dispatch(setLoading(true));
        const params = {
            flow_id: id,
            token: data.token
        };
        http.post(`/flow/${id}/call`, params).then(res => {
            enqueueSnackbar('The flow has been finished', {
                action: CustomSnackbar,
                variant: 'success'
            });
            setResponse(JSON.stringify(res.data));
        }).catch(err => {
            const errorMessage = findErrorKey(err.response.data) ?? `Access denied with token ${data.token} Access token is not correct`;

            enqueueSnackbar(errorMessage, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(response)
            .then(() => {
                enqueueSnackbar(`The response data has been copied to the clipboard.`, {
                    action: CustomSnackbar,
                    variant: 'success'
                });
                onClose();
            }).catch(() => {
            enqueueSnackbar('Unable to copy response to clipboard', {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    return (
        <Dialog
            open={visible}
            onClose={onClose}
            TransitionComponent={Transition}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'flex-start',
                    paddingTop: '150px',
                },
                '& .MuiDialog-paper': {
                    minWidth: '450px',
                    maxWidth: '900px'
                }
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <DialogTitle
                    className="h1"
                    variant="h1"
                    sx={{
                        paddingBottom: '6px',
                        textAlign: 'center',
                        color: '#3B465C',
                    }}
                >
                    Flow { response && ('Response') }
                </DialogTitle>
                <DialogContent style={response ? { maxHeight: '560px' } : {}}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                    {!response ? (
                            <TextField
                                error={!!errors?.name}
                                helperText={errors?.name ? 'This is a required field' : ''}
                                fullWidth
                                id="token"
                                label="Token"
                                name="token"
                                sx={{
                                    marginTop: '10px',
                                }}
                                {...register('token', {
                                    required: true,
                                })}
                            />) : (<BeautifyJson jsonStr={response}/>)}
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        padding: '16px 24px',
                    }}
                >
                    {response && (
                        <Button onClick={copyToClipboard} variant="outlined">
                            Copy
                        </Button>
                    )}
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>
                    {!response && (<Button type="submit" variant="contained">
                        Run
                    </Button>)}
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default FlowRunFlow;
