import { SET_CONTENT_MATCH_MODAL, SET_VISIBLE_MATCH_MODAL } from '../types';

export const setContentMatchModal = (data) => (dispatch) => {
  dispatch({
    type: SET_CONTENT_MATCH_MODAL,
    payload: data,
  });
};

export const setVisibleMatchModal = (data) => (dispatch) => {
  dispatch({
    type: SET_VISIBLE_MATCH_MODAL,
    payload: data,
  });
};
