import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import 'Src/App.scss';
import { createTheme } from '@mui/material/styles';

const bgMainColor = '#333E52';
const bgHeaderColor = '#414D65';
const defaultColorText = '#F2F7F9';
const defaultDanger = '#D76A6A';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#289BEF',
      white: `${defaultColorText}`,
      bg: `${bgMainColor}`,
      bgHeader: `${bgHeaderColor}`,
      blackText: '#3B465C',
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      lineHeight: '17px',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '115%',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: `${defaultColorText}`,
          lineHeight: '120%',
          '&.h1': {
            fontSize: '2.5rem',
            fontWeight: '400',
          },
          '&.text-grey': {
            fontWeight: 500,
            color: 'rgba(148, 157, 176, 0.5)',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.MuiContainer-root': {
            paddingLeft: '35px',
            paddingRight: '35px',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&.nav-link': {
            transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          '&.nav-link.active': {
            color: `${defaultDanger}`,
          },
          '&.nav-link:hover': {
            color: `${defaultDanger}`,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: '16px 0',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: `${bgHeaderColor}`,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '17px',
          fontWeight: '400',
          textTransform: 'none',
          padding: '10px 25px',
          borderRadius: '25px',
          transitionProperty: 'all',
          boxShadow: 'none',
        },
        startIcon: {
          marginRight: '5px',
        },
        contained: {
          color: `${defaultColorText}`,
          backgroundColor: '#289BEF',
          '&:hover': {
            backgroundColor: '#197AC0',
            boxShadow: 'none',
          },
          '&:active': {
            backgroundColor: '#197ABF',
            boxShadow: 'inset 0px 4px 10px rgba(2, 10, 38, 0.45)',
          },
          '&.dark': {
            backgroundColor: '#333E52',
            '&:hover': {
              opacity: '0.9',
            },
            '&:active': {
              boxShadow: 'inset 0px 4px 10px rgba(2, 10, 38, 0.45)',
            },
          },
        },
        containedSecondary: {
          backgroundColor: '#D76A6A',
          '&:hover': { backgroundColor: '#B44848' },
          '&:active': {
            backgroundColor: '#9C3737',
            boxShadow: 'inset 0px 4px 10px rgba(38, 2, 2, 0.45)',
          },
        },
        outlined: {
          padding: '9px 24px',
          color: '#289BEF',
          backgroundColor: 'transparent',
          border: '1px solid #289BEF',
          '.add-icon path': {
            transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          '&:hover': {
            color: '#197AC0',
            border: '1px solid #197AC0',
            backgroundColor: 'transparent',
            '.add-icon path': {
              fill: '#197AC0',
            },
          },
        },
      },
    },
  },
});
