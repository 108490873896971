import React, { useState, useEffect, useCallback } from 'react';
import {Controller, useForm} from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { Box, Grid, Button, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import http from 'Utils/http';
import { setAPIs } from 'Actions/APIs';

export const FlowItem = ({ updateFlow }) => {
    const title = 'Select API Method';
    const dispatch = useDispatch();
    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();

    const flow_id = useSelector((state) => state.flow.flow.id);
    const apiList = useSelector((state) => state.APIs.APIs);

    const [apiMethodList, setApiMethodList] = useState([]);

    const {
        control,
        handleSubmit,
        getValues,
        watch,
        formState: { isValid, isSubmitted },
    } = useForm({
        defaultValues: {
            api: '',
            method: ''
        },
        mode: 'onChange'
    });

    //helper
    const updateApiInList = useCallback((newObj) => {
        const updatedArray = apiList.map(obj => (obj.id === newObj.id ? newObj : obj));
        dispatch(setAPIs(updatedArray));
        sessionStorage.setItem('integrations', JSON.stringify(updatedArray));
    }, [apiList, dispatch]);
    //

    const onSubmit = () => {
        const params = {
            flow_id: flow_id,
            method_id: parseInt(getValues('method')),
        };

        dispatch(setLoading(true));
        http.post(createUrl(`/flow/method/link`), params).then(() => {
            enqueueSnackbar('New Method has been added to the Flow', {action: CustomSnackbar, variant: 'success'});
            updateFlow();
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    useEffect(() => {
        const handleApiChange = async (value, { name, type }) => {
            if (name === 'api') {
                if (!apiList.length) {
                    return;
                }

                let integration = apiList.find(item => item.id === value.api);

                if (!integration?.methods) {
                    try {
                        const integrationUrl = `/integration/${integration.id}`;
                        const res = await http.get(integrationUrl);
                        integration = res?.data?.integration;
                        updateApiInList(integration);
                    } catch (error) {
                        console.error("Error fetching integration:", error);
                        return;
                    }
                }

                if (!integration || !integration.methods.length) {
                    setApiMethodList([]);
                    return;
                }

                let mapped = integration.methods.map(({ id, name, request, response }) => ({
                    id,
                    name,
                    request,
                    response,
                }));

                // For SYSTEM METHOD
                if (integration.description === 'PORT') {
                    const filteredSystemByFlow = integration.methods.filter(item => item.url === `${flow_id}`);
                    mapped = filteredSystemByFlow.map(({ id, name, request, response }) => ({
                        id,
                        name,
                        request,
                        response,
                    }));
                }

                setApiMethodList(mapped);
            }
        };

        const subscription = watch(handleApiChange);

        return () => subscription.unsubscribe();
    }, [watch, apiList, flow_id, updateApiInList]);

    return (
        <>
            {!isSubmitted && (
                <>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box
                            sx={{
                                height: '100%',
                                backgroundColor: '#FAFEFF',
                                border: '1px solid #FFFFFF',
                                boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                                borderRadius: '16px',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                component="form"
                                onSubmit={handleSubmit(onSubmit)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '20px 21.5px 20px 26.5px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '20px',
                                        marginBottom: '20px',
                                        color: '#3B465C',
                                        textAlign: 'center'
                                    }}
                                >
                                    {title}
                                </Typography>

                                <FormControl sx={{ width: '100%', mb: 2 }}>
                                    <InputLabel>Select API</InputLabel>
                                    <Controller
                                        name="api"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                label="Select API"
                                            >
                                                {apiList.map(({ id, name }) => (
                                                    <MenuItem
                                                        key={`${id}_${name}_apiList`}
                                                        value={id}
                                                        disabled={name === 'PORT'}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                <FormControl sx={{ width: '100%', mb: 2 }}>
                                    <InputLabel>Select Method</InputLabel>
                                    <Controller
                                        name="method"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                label="Select Method"
                                            >
                                                {apiMethodList.map(({ id, name }) => (
                                                    <MenuItem key={id} value={id}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                <Button
                                    disabled={!isValid}
                                    type="submit"
                                    variant="outlined"
                                    sx={{
                                        margin: '0 auto',
                                    }}
                                >
                                    Confirm
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </>
            )}
        </>
    );
};
