import { combineReducers } from 'redux';
import auth from './auth';
import loading from './loading';
import message from './message';
import companies from './companies';
import APIs from './APIs';
import operators from './operators';
import matchModal from './matchModal';
import flow from './flow';
import flowMethodResponse from './flowMethodResponse';
import flowMethodList from './flowMethodList';
import { REFRESH_ALL_STATE } from '../types';

const rootReducer = (state, action) => {
  if (action.type === REFRESH_ALL_STATE) {
    state = {};
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  auth,
  loading,
  message,
  companies,
  APIs,
  operators,
  matchModal,
  flow,
  flowMethodResponse,
  flowMethodList,
});

export default rootReducer;
