import React, {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setLoading} from 'Actions/loading';
import {logout} from 'Actions/auth';
import {Box} from '@mui/material';
import {LogautIcon} from 'Shared/Icons';
import {AlertDialog} from 'Shared/AlertDialog';
import {CustomSnackbar} from 'Shared/CustomSnackbar';
import {useSnackbar} from 'notistack';
import http from 'Utils/http';
import {ROUTES} from 'Constants/routes';

export const LogoutComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    if (searchParams.get('action') === 'logout') {
        http.post('/logout').finally(() => {
            dispatch(logout(navigate));
            dispatch(setLoading(false));
            navigate(ROUTES.HOME);
        });
    }

    const {enqueueSnackbar} = useSnackbar();

    const [isActiveModal, setIsActiveModal] = useState(false);
    const onClose = () => setIsActiveModal(false);

    const logoutHandler = () => {
        setIsActiveModal(true);
    };

    const onAgree = () => {
        dispatch(setLoading(true));
        http.post('/logout').finally(() => {
            setIsActiveModal(false);
            dispatch(logout(navigate));
            dispatch(setLoading(false));
            enqueueSnackbar('Thanks you for use Fintegrate portal', {action: CustomSnackbar, variant: 'success'});
            navigate(ROUTES.HOME);
        });
    };

    return (
        <>
            <AlertDialog visible={isActiveModal} onClose={onClose} onAgree={onAgree}/>

            <Box
                onClick={logoutHandler}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '40px',
                    minHeight: '40px',
                    backgroundColor: '#FFFFFF',
                    border: '2px solid rgba(12, 11, 11, 0.1)',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}
            >
                <LogautIcon/>
            </Box>
        </>
    );
};
