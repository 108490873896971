import { SET_MESSAGE, SET_VISIBLE_MESSAGE } from '../types';

const initialState = {
  visible: false,
  message: {
    content: '',
  },
};

const message = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case SET_VISIBLE_MESSAGE:
      return {
        ...state,
        visible: action.payload,
      };
    default:
      return state;
  }
};

export default message;
