import { SET_OPERATORS } from '../types';

const initialState = {
  operators: {
    logical: [],
    other: [],
  },
};

const operators = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPERATORS:
      return {
        ...state,
        operators: action.payload,
      };
    default:
      return state;
  }
};

export default operators;
