import React, { Component } from 'react';
import ErrorBoundryComponent from 'Shared/error-boundary/ErrorBoundryComponent';

export class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(_) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundryComponent />;
    }

    return this.props.children;
  }
}
