import React, { useState, useEffect } from 'react';
import http from 'Utils/http';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography, IconButton, Dialog, DialogContent, TextField } from '@mui/material';
import { Transition } from 'Shared/Transition';
import { CloseIcon, CopyIcon } from 'Shared/Icons';
import { PredefinedInputSelectApiField } from 'Shared/flows/initiate-flow-modal/PredefinedInputSelectApiField';
import { SelectActionsInitiateFlowModal } from 'Shared/flows/initiate-flow-modal/SelectActionsInitiateFlowModal';
import { CreateInputField } from 'Shared/flows/initiate-flow-modal/CreateInputField';
import { useIsFirstRender } from 'Hooks/useIsFirstRender';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
export const InitiateFlowModal = ({ visible, onClose, needRefresh, setNeedRefresh }) => {
    const isFirst = useIsFirstRender();
    const { enqueueSnackbar } = useSnackbar();
    const { createUrl } = useCreateUrl();
    const { flow_id } = useParams();

    const flow_id_redux = useSelector((state) => state.flow.flow?.id);
    const flowUrl = useSelector((state) => state.flow.flow?.url);

    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (!flow_id_redux || +flow_id_redux !== +flow_id || !needRefresh) {
                return;
            }

            if (sessionStorage.getItem('inputs') && isFirst) {
                const inputs = JSON.parse(sessionStorage.getItem('inputs'));
                setInputs([...inputs]);
                return;
            }

            try {
                const res = await http.get(createUrl(`/input/${flow_id}/fields`));
                const fields = Object.values(res.data.fields).sort((a, b) => a.request_field_id - b.request_field_id);
                setInputs(fields || []);
                sessionStorage.setItem('inputs', JSON.stringify(fields));
            } catch (err) {
                console.error(err, 'err');
            } finally {
                setNeedRefresh(false);
            }
        };

        fetchData();
    }, [flow_id, flow_id_redux, isFirst, setNeedRefresh, createUrl, needRefresh]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(flowUrl);
        enqueueSnackbar('URL copied to clipboard', {action: CustomSnackbar, variant: 'success'});
    };

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '75vw',
                    minHeight: '70vh',
                    backgroundColor: '#FAFEFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '16px',
                    boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                },
            }}
            open={visible}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                }}
            >
                <IconButton size="large" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="large"/>
                </IconButton>
            </Box>
            <DialogContent
                sx={{
                    display: 'flex',
                    padding: '35px 30px 20px 25px',
                    overflow: 'hidden',
                }}
            >
                <Grid container direction="row">
                    <SelectActionsInitiateFlowModal />
                    {/* right */}
                    <Grid
                        item
                        xs={8}
                        sx={{
                            height: '100%',
                            paddingLeft: '25px',
                            borderRadius: '10px',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                padding: '10px 5px 20px 20px',
                                background: '#F2F7F9',
                                border: '1px solid rgba(148, 157, 176, 0.25)',
                                borderRadius: '10px',
                                height: 'inherit',
                            }}
                        >
                            <Box
                                className="scroll-container"
                                sx={{
                                    paddingTop: '10px',
                                    paddingRight: '15px',
                                    overflowY: 'auto',
                                    height: 'inherit',
                                }}
                            >
                                <Typography
                                    sx={{
                                        marginBottom: '25px',
                                        fontWeight: '700',
                                        letterSpacing: '0.1em',
                                        color: 'rgba(65, 77, 101, 0.85)',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    Flow ID: {flow_id}
                                </Typography>
                                <Typography
                                    sx={{
                                        marginBottom: '15px',
                                        fontWeight: '500',
                                        color: '#949DB0',
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    Flow Endpoint:
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '25px',
                                    }}
                                >
                                    <TextField
                                        value={flowUrl}
                                        readOnly
                                        fullWidth
                                        disabled={true}
                                        sx={{
                                            marginRight: '10px',
                                            '& .MuiInputBase-input': {
                                                color: '#949DB0',
                                            },
                                        }}
                                    />
                                    <Box
                                        onClick={copyToClipboard}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '53px',
                                            height: '53px',
                                            background: 'rgba(59, 70, 92, 0.05)',
                                            border: '1px solid rgba(65, 77, 101, 0.03)',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                                            '&:hover': {
                                                background: 'rgba(59, 70, 92, 0.15)',
                                            },
                                        }}
                                    >
                                        <CopyIcon/>
                                    </Box>
                                </Box>
                                <Typography
                                    sx={{
                                        marginBottom: '3px',
                                        fontWeight: '700',
                                        letterSpacing: '0.1em',
                                        color: 'rgba(65, 77, 101, 0.85)',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    List of parameters
                                </Typography>
                                <Typography
                                    sx={{
                                        marginBottom: '15px',
                                        fontWeight: '500',
                                        color: '#949DB0'
                                    }}
                                >
                                    Type any parameters here Add all your GET parameters and their default values
                                </Typography>
                                {/* inputs list */}
                                <Box
                                    sx={{
                                        padding: '26px 2px 15px 10px',
                                        marginBottom: '25px',
                                        background: 'rgba(65, 77, 101, 0.05)',
                                        borderRadius: '6px',
                                    }}
                                >
                                    {inputs?.length > 0 && inputs.map((input) => (
                                        <PredefinedInputSelectApiField
                                            key={input.field_id}
                                            input={input}
                                            setNeedRefresh={setNeedRefresh}
                                            flow_id={flow_id}
                                        />
                                    ))}
                                    <CreateInputField inputs={inputs} setNeedRefresh={setNeedRefresh}/>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
