import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  IconButton,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button, Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import { CopyIcon } from 'Shared/Icons';
import { AlertDialog } from 'Shared/AlertDialog';
import FlowsGraph from 'Shared/flows/FlowsGraph';
import FlowCopyForm from 'Shared/flows/flows-interface/FlowCopyForm';

export const AdminFlowsItem = ({ item, needUpdate, deleteFlow, changeStatusFlow }) => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [visibleCopyFlowForm, setVisibleCopyFlowForm] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState('Yes');
  const [skipButtonText, setSkipButtonText] = useState('No');
  const [modalAction, setModalAction] = useState('change');
  const [modalTitle, setModalTitle] = useState('Confirm your action');
  const [modalText, setModalText] = useState(
      <span>Change <strong>{item.name}</strong> availability?</span>
  );

  const toFlow = (event, id) => {
    event.preventDefault();
    navigate(`/flow/${id}`);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (event.target.classList.contains('PrivateSwitchBase-input') || event.target.classList.contains('MuiButtonBase-root')) {
      return;
    }
    setExpanded(isExpanded ? panel : false);
  };

  const handleCopy = (e) => {
    e.stopPropagation();
    setVisibleCopyFlowForm(true);
  };

  const handleChangeSwitch = (e) => {
    e.preventDefault();
    setModalTitle('Confirm your action');
    setModalText(<span>Change <strong>{item.name}</strong> availability?</span>);
    setConfirmButtonText('Yes');
    setSkipButtonText('No');
    setModalAction('change');
    setIsActiveModal(true);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setModalTitle(`Delete "${item.name}"?`);
    setModalText(<span>Are you sure you want to delete <strong>{item.name}</strong>?</span>);
    setConfirmButtonText('Delete');
    setSkipButtonText('Cancel');
    setModalAction('delete');
    setIsActiveModal(true);
  };

  const onClose = () => setIsActiveModal(false);

  const onAgree = () => {
    setIsActiveModal(false);
    if (modalAction === 'delete') {
      deleteFlow(item);
    } else if (modalAction === 'change') {
      const updatedItem = { ...item, status: item.status === 'active' ? 'disabled' : 'active' };
      changeStatusFlow(updatedItem);
    }
  };

  const skipButton = modalAction === 'delete' ? (
      <Button
          sx={{ backgroundColor: '#e9e9e9', color: '#707070', borderRadius: '5px' }}
          onClick={onClose}
      >
        {skipButtonText}
      </Button>
  ) : null;

  const confirmButton = modalAction === 'delete' ? (
      <Button
          sx={{
            backgroundColor: '#f44336',
            color: '#fff',
            borderRadius: '5px',
            '&:hover': { backgroundColor: '#bf2317' },
          }}
          onClick={onAgree}
      >
        {confirmButtonText}
      </Button>
  ) : null;

  return (
      <>
        <AlertDialog
            visible={isActiveModal}
            onClose={onClose}
            onAgree={onAgree}
            text={modalText}
            title={modalTitle}
            noButton={skipButton}
            yesButton={confirmButton}
            yesButtonText={confirmButtonText}
            noButtonText={skipButtonText}
        />
        <FlowCopyForm
            id={item.id}
            needUpdateList={needUpdate}
            setVisibleFlowForm={setVisibleCopyFlowForm}
            visibleFlowForm={visibleCopyFlowForm}
        />
        <Accordion
            sx={{ marginBottom: '20px' }}
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
        >
          <AccordionSummary
              sx={{
                backgroundColor: '#F2F5F8',
                '& .MuiAccordionSummary-content': {alignItems: 'center'},
              }}
              expandIcon={
              <Tooltip title={
                <span style={{fontSize: '1rem', lineHeight: '120%'}}>Edit Flow</span>
              } arrow>
                <IconButton onClick={(e) => toFlow(e, item.id)}>
                  <SettingsIcon/>
                </IconButton>
              </Tooltip>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header">
            <Typography
                variant="h6"
                sx={{ color: '#3B465C', marginRight: '20px', flexShrink: 0 }}
            >
              {item?.name}
            </Typography>

            <Tooltip title={
              <span style={{fontSize: '1rem', lineHeight: '120%'}}>Availability</span>
            } arrow>
              <Switch
                  sx={{ marginLeft: 'auto' }}
                  checked={item.status === 'active'}
                  color="primary"
                  onClick={handleChangeSwitch}
              />
            </Tooltip>

            <Tooltip title={
              <span style={{ fontSize: '1rem', lineHeight: '120%' }}>Usage Statistics</span>
            } arrow>
              <IconButton
                  sx={{ marginRight: '14px', color: expanded ? '#F4B156' : '' }}
                  aria-label="open-chart"
              >
                <StackedBarChartIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={
              <span style={{ fontSize: '1rem', lineHeight: '120%' }}>Copy Flow</span>
            } arrow>
              <IconButton
                  sx={{ marginRight: '14px', color: '#f44336' }}
                  onClick={handleCopy}
                  aria-label="copy"
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={
              <span style={{ fontSize: '1rem', lineHeight: '120%' }}>Delete Flow</span>
            } arrow>
              <IconButton
                  sx={{ marginRight: '14px', color: '#f44336' }}
                  onClick={handleDelete}
                  aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            {expanded ? <FlowsGraph item={item} /> : null}
          </AccordionDetails>
        </Accordion>
      </>
  );
};
