import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, IconButton } from '@mui/material';
import { setLoading } from 'Actions/loading';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import http from 'Utils/http';
import { useCreateUrl } from 'Hooks/useCreateUrl';

export const PredefinedSelectApiField = ({ output, setNeedRefresh, user_flow_id, apiList, flowMethodList }) => {
    const dispatch = useDispatch();
    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();
    const handleDelete = (e, output) => {
        dispatch(setLoading(true));
        http.delete(createUrl(`/output/${user_flow_id}/field/${output.delete_id}/delete`)).then(() => {
            enqueueSnackbar('The output port has been deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
            setNeedRefresh(true);
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                marginBottom: '16px',
            }}
        >
            <FormControl
                sx={{
                    width: '100%',
                    marginRight: '10px',
                    backgroundColor: '#F2F5F8',
                }}
            >
                <InputLabel>API</InputLabel>
                <Select disabled defaultValue={output?.integration_id} label="API">
                    {apiList.map((item) => {
                        return (
                            <MenuItem key={`${item.id}api`} value={item.id}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

            {/* method */}
            <FormControl
                sx={{
                    width: '100%',
                    marginRight: '10px',
                    backgroundColor: '#F2F5F8',
                }}
            >
                <InputLabel>Method</InputLabel>
                <Select disabled defaultValue={output?.source_flow_method_id} label="Method">
                    {flowMethodList?.map((item) => {
                        return (
                            <MenuItem key={`${item.method.id}method`} value={item.id}>
                                {item.name ? item.name : item.method.name} ({item.id})
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

            {/* field */}
            <FormControl
                sx={{
                    width: '100%',
                    marginRight: '10px',
                    backgroundColor: '#F2F5F8',
                }}
            >
                <TextField label="Field" disabled fullWidth defaultValue={output?.name}
                           InputLabelProps={{shrink: true}}/>
            </FormControl>

            <IconButton
                sx={{
                    width: '53px',
                    color: '#f44336',
                }}
                onClick={(e) => {
                    handleDelete(e, output);
                }}
                aria-label="delete"
            >
                <DeleteIcon/>
            </IconButton>
        </Box>
    );
};
