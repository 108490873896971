import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { setAPIs } from 'Actions/APIs';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import MethodsParamsSearchForm from 'Components/methods-params/MethodsParamsSearchForm';
import { AlertDialog } from 'Shared/AlertDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import http from 'Utils/http';

const MethodsParametersPage = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [methodsList, setMethodsList] = useState([]);
    const [filteredMethodsList, setFilteredMethodsList] = useState([]);
    const [needRefresh, setNeedRefresh] = useState(true);
    const [isActiveItem, setIsActiveItem] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //helper
    const handleFields = (fields, fieldArray, integration, method) => {
        if (fieldArray.length) {
            fieldArray.forEach((field) => {
                fields.push({
                    integration_name: integration.name,
                    method_id: method.id,
                    method_name: method.name,
                    field_id: field.id,
                    field_name: field.name,
                    field_title: field.title,
                    field_type: field.type,
                    field_type_for: field.for,
                    field_in: field.in,
                    field_where: field.where,
                });
            });
        }
    }
    //

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!needRefresh) {
                    return;
                }

                const cachedIntegrations = sessionStorage.getItem('integrations');
                const cachedFields = sessionStorage.getItem('methods-list');
                if (cachedIntegrations && cachedFields) {
                    dispatch(setAPIs(JSON.parse(cachedIntegrations)));
                    setMethodsList(JSON.parse(cachedFields));
                    setFilteredMethodsList(JSON.parse(cachedFields));
                    return;
                }

                dispatch(setLoading(true));

                const integrationsResponse = await http.get('/integrations');
                const integrationPromises = integrationsResponse.data.integrations.map((item) => {
                    const integrationUrl = `/integration/${item.id}`;
                    return http.get(integrationUrl);
                });
                const integrationResponses = await Promise.all(integrationPromises);

                const fields = [];

                const integrations = [];
                integrationResponses.forEach((response) => {
                    const integration = response.data?.integration;
                    if (integration) {
                        integrations.push(integration);
                        integration.methods.forEach((method) => {
                            handleFields(fields, method.request, integration, method);
                            handleFields(fields, method.response, integration, method);
                        });
                    }
                });
                dispatch(setAPIs(integrations));

                // Save data to sessionStorage for caching
                sessionStorage.setItem('integrations', JSON.stringify(integrations));
                sessionStorage.setItem('methods-list', JSON.stringify(fields));

                setMethodsList(fields);
                setFilteredMethodsList(fields);
            } catch (error) {
                console.error("Error fetching integrations:", error);
            } finally {
                dispatch(setLoading(false));
                setNeedRefresh(false);
            }
        };

        fetchData();
    }, [needRefresh, dispatch]);

    const [modalText] = useState(
        'This method can be used in existent flows. Removing it will break these flows. Are you sure?'
    );
    const [isActiveModal, setIsActiveModal] = useState(false);
    const onClose = () => setIsActiveModal(false);

    const onAgree = () => {
        setIsActiveModal(false);
        dispatch(setLoading(true));
        http.delete(`/user/field/${isActiveItem}/delete`).then((res) => {
            enqueueSnackbar('Your field has been deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
            onClose();
            setNeedRefresh(true);
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
            dispatch(setLoading(false));
        });
    };

    return (
        <>
            <AlertDialog visible={isActiveModal} onClose={onClose} onAgree={onAgree} text={modalText}/>
            <Typography
                variant="h1"
                sx={{
                    textAlign: 'center',
                    marginBottom: '25px',
                    fontSize: '3rem',
                    fontWeight: 500,
                }}
            >
                Methods Parameters
            </Typography>
            <Box component={Paper} sx={{padding: '20px 20px 0px 20px'}}>
                <Box
                    sx={{
                        marginBottom: '20px',
                    }}
                >
                    <MethodsParamsSearchForm
                        methodsList={methodsList}
                        setFilteredMethodsList={setFilteredMethodsList}
                        setPage={setPage}
                        setNeedRefresh={setNeedRefresh}
                    />
                </Box>

                <TableContainer>
                    <Table sx={{minWidth: 650}} size="medium">
                        <TableHead>
                            <TableRow
                                sx={{
                                    height: '50px',
                                    '& .MuiTableCell-root': {
                                        fontSize: '1.2rem',
                                    },
                                }}
                            >
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">Integration Name</TableCell>
                                <TableCell align="center">Method Name</TableCell>
                                <TableCell align="center">Field Direction</TableCell>
                                <TableCell align="center">Field Name</TableCell>
                                <TableCell align="center">Field Title</TableCell>
                                <TableCell align="center">Field In</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredMethodsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                <TableRow
                                    key={`${item.field_id}`}
                                    sx={{
                                        '& .MuiTableCell-root': {
                                            fontSize: '1.2rem',
                                        },
                                        '&:last-child td, &:last-child th': {border: 0},
                                    }}
                                >
                                    <TableCell align="center">{index + 1 + page * rowsPerPage}</TableCell>
                                    <TableCell
                                        align="center">{item?.integration_name ? item.integration_name : '-'}</TableCell>
                                    <TableCell align="center">{item?.method_name ? item.method_name : '-'}</TableCell>
                                    <TableCell
                                        align="center">{item?.field_type_for ? item.field_type_for : '-'}</TableCell>
                                    <TableCell align="center">{item?.field_name ? item.field_name : '-'}</TableCell>
                                    <TableCell align="center">{item?.field_title ? item.field_title : '-'}</TableCell>
                                    <TableCell align="center">{item?.field_in ? item.field_in : '-'}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            sx={{
                                                '& .MuiSvgIcon-fontSizeMedium': {
                                                    fontSize: '17px',
                                                },
                                            }}
                                            onClick={() => {
                                                setIsActiveModal(true);
                                                setIsActiveItem(item.field_id);
                                            }}
                                            startIcon={<DeleteIcon/>}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Remove
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    {!filteredMethodsList.length && (
                        <Typography
                            sx={{
                                marginTop: '24px',
                                marginBottom: '24px',
                                textAlign: 'center',
                                fontSize: '2rem',
                                fontWeight: 500,
                                color: '#D76A6A',
                            }}
                        >
                            Items Not found
                        </Typography>
                    )}
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={filteredMethodsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </>
    );
};

export default MethodsParametersPage;
