import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { MatchDisabledIcon, MatchedIcon, BranchIcon, BranchIconGrey } from 'Shared/Icons';
import { setContentMatchModal, setVisibleMatchModal } from 'Actions/matchModal';

const FlowItemFieldRows = ({ request, response, rowsCount, itemInfo, integrationName }) => {
    const dispatch = useDispatch();
    const [itemsArray, setItemsArray] = useState(new Array(rowsCount).fill('1'));

    useEffect(() => {
        setItemsArray(new Array(rowsCount).fill('1'));
    }, [rowsCount]);

    const openMatchModal = (item) => {
        dispatch(setVisibleMatchModal(true));
        dispatch(
            setContentMatchModal({
                ...itemInfo,
                field: item,
                title: `${integrationName}.${itemInfo.method.name}.${item.name}`,
            })
        );
    };

    return (
        <Box
            className="scroll-container"
            sx={{
                paddingBottom: '50px',
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px',
                backgroundColor: '#F2F5F9',
            }}
        >
            {itemsArray.map((_, index) => {
                return (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            padding: '13px 35px',
                            '&.MuiBox-root:nth-of-type(even)': {
                                backgroundColor: 'rgba(148, 157, 176, 0.1)',
                            },
                        }}
                    >
                        {/* input */}
                        <Box
                            sx={{
                                width: '50%',
                                paddingRight: '16px',
                            }}
                        >
                            {(request?.length > 0 && request[index]?.name) && (
                                <>
                                    <Typography
                                        sx={{
                                            marginBottom: '15px',
                                            color: '#3B465C',
                                            textAlign: 'left',
                                            wordBreak: 'break-all',
                                        }}
                                    >
                                        {request[index]?.name}
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => openMatchModal(request[index])}
                                    >
                                        <MatchedIcon/>
                                        <Typography
                                            sx={{
                                                marginRight: '5px',
                                                marginLeft: '5px',
                                                color: '#289BEF',
                                            }}
                                        >
                                            Match
                                        </Typography>
                                        {request[index]?.type === 'array' && <BranchIcon/>}
                                    </Box>
                                </>
                            )}
                        </Box>
                        {/* output */}
                        <Box
                            sx={{
                                width: '50%',
                                paddingLeft: '16px',
                            }}
                        >
                            {(response?.length > 0 && response[index]?.name) && (
                                <>
                                    <Typography
                                        sx={{
                                            marginBottom: '15px',
                                            color: '#3B465C',
                                            textAlign: 'right',
                                            wordBreak: 'break-all',
                                        }}
                                    >
                                        {response[index]?.name}
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        <MatchDisabledIcon/>
                                        <Typography
                                            sx={{
                                                marginRight: response[index]?.type === 'array' ? '5px' : '0px',
                                                marginLeft: '5px',
                                                color: '#949DB0',
                                            }}
                                        >
                                            Match
                                        </Typography>
                                        {response[index]?.type === 'array' ? <BranchIconGrey/> : ''}
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export default FlowItemFieldRows;
