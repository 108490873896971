import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import {
    Box,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    FormHelperText,
    IconButton,
} from '@mui/material';
import { Transition } from 'Shared/Transition';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
import { CloseIcon } from 'Shared/Icons';
import http from 'Utils/http';

const fieldTypeList = ['string', 'integer', 'double', 'object', 'array'];
const fieldTypeForList = ['request', 'response'];
const fieldTypeInList = ['header', 'path', 'query', 'body'];

export const AddMethodsParamsModal = ({visible, onClose, setNeedRefresh}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const apiList = useSelector((state) => state.APIs.APIs);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ mode: 'onChange' });

    const [apiMethodList, setApiMethodList] = useState([]);

    useEffect(() => {
        const handleIntegrationChange = (value, { name, type }) => {
            if (name === 'integration_id') {
                const filteredApi = apiList.find(({ id }) => id === value.integration_id);

                if (!filteredApi || !filteredApi.methods.length) {
                    setApiMethodList([]);
                    return;
                }

                const mappedMethods = filteredApi.methods.map(({ id, name }) => ({ id, name }));
                setApiMethodList(mappedMethods);
            }
        };

        const subscription = watch(handleIntegrationChange);

        return () => subscription.unsubscribe();
    }, [watch, apiList]);


    const onSubmit = (data) => {
        const params = {
            integration_id: data.integration_id,
            method_id: data.method_id,
            name: data.field_name,
            title: data.field_title,
            type: data.field_type,
            for: data.field_for,
            in: data.field_in,
            where: data.field_where,
        };

        dispatch(setLoading(true));
        http
            .post(`/field/create`, params)
            .then((res) => {
                enqueueSnackbar('Your field has been saved', {action: CustomSnackbar, variant: 'success'});
                onClose();
            })
            .catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {action: CustomSnackbar, variant: 'error'});
            })
            .finally(() => {
                setNeedRefresh(true);
                dispatch(setLoading(false));
            });
    };

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '50vw',
                    backgroundColor: '#FAFEFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '16px',
                    boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                },
            }}
            open={visible}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                }}
            >
                <IconButton size="large" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="large"/>
                </IconButton>
            </Box>
            <DialogTitle
                color="primary.blackText"
                sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                }}
            >
                Add new method params
            </DialogTitle>

            <DialogContent
                sx={{
                    display: 'flex',
                    padding: '35px 30px 20px 25px',
                    overflow: 'hidden',
                }}
            >
                <Grid container direction="row">
                    <Grid
                        item
                        xs={12}
                        sx={{
                            height: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            sx={{
                                height: 'inherit',
                            }}
                        >
                            <Box
                                className="scroll-container"
                                sx={{
                                    paddingTop: '10px',
                                    paddingRight: '15px',
                                    overflowY: 'auto',
                                    height: 'inherit',
                                }}
                            >
                                <FormControl
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    error={errors.integration_id ? true : false}
                                >
                                    <InputLabel>Integration</InputLabel>
                                    <Select
                                        defaultValue=""
                                        label="Integration"
                                        {...register('integration_id', {
                                            required: true,
                                        })}
                                    >
                                        {apiList.map((item) => {
                                            return (
                                                <MenuItem key={`${item.id}_integration_id`} value={item.id}
                                                          disabled={item?.name === 'PORT'}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.integration_id ?
                                        <FormHelperText>This is a required field</FormHelperText> : ''}
                                </FormControl>
                                {/* method */}
                                <FormControl
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    error={errors.method_id ? true : false}
                                >
                                    <InputLabel>Method</InputLabel>
                                    <Select
                                        defaultValue=""
                                        label="Method"
                                        {...register('method_id', {
                                            required: true,
                                        })}
                                    >
                                        {apiMethodList.map((item) => {
                                            return (
                                                <MenuItem key={`${item.id}_method_id`} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.method_id ? <FormHelperText>This is a required field</FormHelperText> : ''}
                                </FormControl>
                                <TextField
                                    error={errors?.field_name ? true : false}
                                    helperText={errors?.field_name ? 'This is a required field' : ''}
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    label="Field Name"
                                    {...register('field_name', {required: true})}
                                />
                                <TextField
                                    error={errors?.field_title ? true : false}
                                    helperText={errors?.field_title ? 'This is a required field' : ''}
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    label="Field Title"
                                    {...register('field_title', {})}
                                />
                                <FormControl
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    error={errors.field_type ? true : false}
                                >
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        defaultValue="string"
                                        label="Type"
                                        {...register('field_type', {
                                            // required: true,
                                        })}
                                    >
                                        {fieldTypeList.map((item) => {
                                            return (
                                                <MenuItem key={`${item}_field_type`} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.field_type ? <FormHelperText>This is a required field</FormHelperText> : ''}
                                </FormControl>
                                <FormControl
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    error={errors.field_for ? true : false}
                                >
                                    <InputLabel>For</InputLabel>
                                    <Select
                                        defaultValue="request"
                                        label="For"
                                        {...register('field_for', {
                                            required: true,
                                        })}
                                    >
                                        {fieldTypeForList.map((item) => {
                                            return (
                                                <MenuItem key={`${item}_field_for`} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.field_for ? <FormHelperText>This is a required field</FormHelperText> : ''}
                                </FormControl>

                                <FormControl
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    error={errors.field_in ? true : false}
                                >
                                    <InputLabel>In</InputLabel>
                                    <Select
                                        defaultValue="query"
                                        label="In"
                                        {...register('field_in', {
                                            // required: true,
                                        })}
                                    >
                                        {fieldTypeInList.map((item) => {
                                            return (
                                                <MenuItem key={`${item}_field_in`} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.field_in ? <FormHelperText>This is a required field</FormHelperText> : ''}
                                </FormControl>

                                <FormControl
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    error={errors.field_where ? true : false}
                                >
                                    <InputLabel>Where</InputLabel>
                                    <Select
                                        defaultValue=""
                                        label="Where"
                                        {...register('field_where', {
                                            // required: true,
                                        })}
                                    >
                                        {fieldTypeInList.map((item) => {
                                            return (
                                                <MenuItem key={`${item}_field_where`} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.field_where ?
                                        <FormHelperText>This is a required field</FormHelperText> : ''}
                                </FormControl>

                                <Button
                                    sx={{
                                        padding: '16px 26px',
                                        fontSize: '16px',
                                        margin: '0 auto 8px auto',
                                        width: '100%',
                                        borderRadius: '4px',
                                    }}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Add Method Params
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
