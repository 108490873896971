import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const SwitchOption = ({ label, activeSwitch, switchType, setActiveSwitch }) => {
    const handleClick = () => {
        setActiveSwitch(switchType);
    };

    return (
        <Box
            onClick={handleClick}
            sx={{
                padding: '5px 10px',
                width: '50%',
                backgroundColor: activeSwitch === switchType ? '#289BEF' : 'transparent',
                borderRadius: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            }}
        >
            <Typography
                sx={{
                    fontWeight: '500',
                    fontSize: '13px',
                    lineHeight: '18px',
                    textAlign: 'center',
                    letterSpacing: '-0.078px',
                    color: '#FFFFFF',
                }}
            >
                {label}
            </Typography>
        </Box>
    );
};

export const CustomSwitch = ({activeSwitch, setActiveSwitch}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: '#414D65',
                border: '2px solid #414D65',
                borderRadius: '15px',
                overflow: 'hidden',
                cursor: 'pointer',
            }}
        >
            <SwitchOption
                label="All"
                activeSwitch={activeSwitch}
                switchType="all"
                setActiveSwitch={setActiveSwitch}
            />
            <SwitchOption
                label="Not Matched"
                activeSwitch={activeSwitch}
                switchType="not-matched"
                setActiveSwitch={setActiveSwitch}
            />
            <SwitchOption
                label="Matched"
                activeSwitch={activeSwitch}
                switchType="matched"
                setActiveSwitch={setActiveSwitch}
            />
        </Box>
    );
};
