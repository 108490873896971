import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { SelectApiField } from 'Shared/flows/finalizate-flow-modal/SelectApiField';
import { PredefinedSelectApiField } from 'Shared/flows/finalizate-flow-modal/PredefinedSelectApiField';
import { SendEmailForm } from 'Shared/flows/finalizate-flow-modal/SendEmailForm';

export const SendEmail = ({ outputs, setNeedRefresh, operationId }) => {
  const user_flow_id = useSelector((state) => state.flow.flow?.id);
  const flowMethodList = useSelector((state) => state.flowMethodList.flowMethodList);
  const apiList = useSelector((state) => state.APIs.APIs);

  return (
    <Box>
      <SendEmailForm operationId={operationId} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '15px 12px',
          marginBottom: '25px',
          background: 'rgba(65, 77, 101, 0.05)',
          borderRadius: '6px',
        }}
      >
        <Typography
          sx={{
            marginBottom: '16px',
            fontWeight: '500',
            color: '#949DB0',
          }}
        >
          API field to be sent
        </Typography>

        {/* Predined list */}
        {outputs &&
          outputs.map((output, index) => {
            return (
              <PredefinedSelectApiField
                key={output.field_id}
                output={output}
                setNeedRefresh={setNeedRefresh}
                user_flow_id={user_flow_id}
                apiList={apiList}
                flowMethodList={flowMethodList}
              />
            );
          })}
        <SelectApiField setNeedRefresh={setNeedRefresh} />
      </Box>
    </Box>
  );
};
