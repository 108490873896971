import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export const SelectActionsInitiateFlowModal = () => {
  const { register } = useForm({});

  return (
    <>
      <Grid item xs={4}>
        <Typography
          variant="h2"
          sx={{
            marginBottom: '5px',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '24px',
            color: '#3B465C',
          }}
        >
          Initiate Flow
        </Typography>

        <Typography
          sx={{
            textAlign: 'center',
            marginBottom: '35px',
            color: '#949DB0',
          }}
        >
          Please set up default values for the input fields, that can be used in GET or POST requests
        </Typography>

        <FormControl
          sx={{
            width: '100%',
            marginBottom: '40px',
          }}
        >
          <InputLabel>Initiate from</InputLabel>
          <Select
            label="Initiate from"
            defaultValue={30}
            {...register('initiateFrom', {
              required: true,
            })}
          >
            <MenuItem value={30}>From Url</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};
