export const downloadFile = (data, fileName) => {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}`;
  a.click();
  a.remove();
};

export const openFileIntoNewTab = (data, fileName) => {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.click();
  a.remove();
};
