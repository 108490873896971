import { SET_APIS } from '../types';

const initialState = {
  APIs: [],
};

const APIs = (state = initialState, action) => {
  switch (action.type) {
    case SET_APIS:
      return {
        ...state,
        APIs: action.payload,
      };
    default:
      return state;
  }
};

export default APIs;
