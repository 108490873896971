import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { setAPIs } from 'Actions/APIs';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import MethodsSearchForm from 'Components/methods/MethodsSearchForm';
import http from 'Utils/http';
import { AlertDialog } from 'Shared/AlertDialog';
import DeleteIcon from '@mui/icons-material/Delete';

const MethodsPage = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [methodsList, setMethodsList] = useState([]);
    const [filteredMethodsList, setFilteredMethodsList] = useState([]);
    const [needRefresh, setNeedRefresh] = useState(true);

    useEffect(() => {
        if (!needRefresh) {
            return;
        }

        dispatch(setLoading(true));
        http.get('/integrations').then((res) => {
            let results = [];
            let integrations = [];
            const promises = res.data.integrations.map((item) => {
                const integrationUrl = `/integration/${item.id}`;
                return http.get(integrationUrl);
            });
            Promise.all(promises)
                .then((res) => {
                    res.forEach((item) => {
                        if (item.data?.integration) {
                            integrations.push(item.data.integration);
                            let mapped = item.data.integration.methods
                                .filter((method) => {
                                    return method.name !== 'Output Port' && method.name !== 'Input Port';
                                })
                                .map((method) => {
                                    return {
                                        ...method,
                                        integration_name: item.data.integration.name,
                                    };
                                });
                            results.push(...mapped);
                        }
                    });
                }).then(() => {
                dispatch(setAPIs(integrations));
                setMethodsList(results);
                setFilteredMethodsList(results);
            });

            dispatch(setLoading(false));
        }).catch(() => {
            dispatch(setLoading(false));
        }).finally(() => {
            setNeedRefresh(false);
        });
    }, [needRefresh, dispatch]);

    const [modalText] = useState(
        'This method can be used in existent flows. Removing it will break these flows. Are you sure?'
    );
    const [isActiveModal, setIsActiveModal] = useState(false);
    const onClose = () => setIsActiveModal(false);
    const [isActiveItem, setIsActiveItem] = useState(null);

    const onAgree = () => {
        setIsActiveModal(false);
        dispatch(setLoading(true));
        http.delete(`/method/${isActiveItem}/delete`).then((res) => {
            enqueueSnackbar('Your method has been deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
            onClose();
            setNeedRefresh(true);
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    return (
        <>
            <AlertDialog visible={isActiveModal} onClose={onClose} onAgree={onAgree} text={modalText}/>
            <Typography
                variant="h1"
                sx={{
                    textAlign: 'center',
                    marginBottom: '25px',
                    fontSize: '3rem',
                    fontWeight: 500,
                }}
            >
                API Methods
            </Typography>
            <Box component={Paper} sx={{padding: '20px 20px 0px 20px'}}>
                <Box
                    sx={{
                        marginBottom: '20px',
                    }}
                >
                    <MethodsSearchForm
                        methodsList={methodsList}
                        setFilteredMethodsList={setFilteredMethodsList}
                        setPage={setPage}
                        setNeedRefresh={setNeedRefresh}
                    />
                </Box>

                <TableContainer>
                    {/* {JSON.stringify(results)} */}
                    <Table sx={{minWidth: 650}} size="medium">
                        <TableHead>
                            <TableRow
                                sx={{
                                    height: '50px',
                                    '& .MuiTableCell-root': {
                                        fontSize: '1.2rem',
                                    },
                                }}
                            >
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">Integration Name</TableCell>
                                <TableCell align="center">Method Name</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredMethodsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                <TableRow
                                    key={`${item.integration_name}_${item.name}_${item.id}`}
                                    sx={{
                                        '& .MuiTableCell-root': {
                                            fontSize: '1.2rem',
                                        },
                                        '&:last-child td, &:last-child th': {border: 0},
                                    }}
                                >
                                    <TableCell align="center">{index + 1 + page * rowsPerPage}</TableCell>
                                    <TableCell
                                        align="center">{item?.integration_name ? item.integration_name : '-'}</TableCell>
                                    <TableCell align="center">{item?.name ? item.name : '-'}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            sx={{
                                                '& .MuiSvgIcon-fontSizeMedium': {
                                                    fontSize: '17px',
                                                },
                                            }}
                                            onClick={() => {
                                                setIsActiveModal(true);
                                                setIsActiveItem(item.id);
                                            }}
                                            startIcon={<DeleteIcon/>}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Remove
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    {!filteredMethodsList.length && (
                        <Typography
                            sx={{
                                marginTop: '24px',
                                marginBottom: '24px',
                                textAlign: 'center',
                                fontSize: '2rem',
                                fontWeight: 500,
                                color: '#D76A6A',
                            }}
                        >
                            Items Not found
                        </Typography>
                    )}
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[25, 50]}
                    component="div"
                    count={filteredMethodsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </>
    );
};

export default MethodsPage;
