import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box, FormControl, FormHelperText, IconButton, TextField } from '@mui/material';
import { setLoading } from 'Actions/loading';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import http from 'Utils/http';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";

const isValidJSON = (jsonString) => {
    return jsonString.startsWith('{') || jsonString.startsWith('[') || jsonString.startsWith('<');
}

export const PredefinedInputSelectApiField = ({ input, setNeedRefresh, flow_id }) => {
    const dispatch = useDispatch();

    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();
    const [type, setType] = useState(isValidJSON(input?.default) ? 'OBJECT' : 'FIELD');
    const [code, setCode] = useState(isValidJSON(input?.default) ? input?.default : '');

    const {
        register,
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { errors}
    } = useForm({
        defaultValues: {
            defaultValue: input?.default
        },
        mode: 'onChange'
    });

    useEffect(() => {
        const subscription = watch((values) => {
            const { defaultValue } = values;
            const isType = isValidJSON(defaultValue);
            setType(isType ? 'OBJECT' : 'FIELD');

            if (isType) {
                setCode(defaultValue);
            } else {
                setCode('');
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, setType, setValue, setCode]);

    const handleDelete = (e, input) => {
        dispatch(setLoading(true));
        http.delete(`/input/${flow_id}/field/${input.field_id}/delete`).then((res) => {
            enqueueSnackbar('The input parameter has been deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
            setNeedRefresh(true);
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const onSubmit = (data) => {
        const params = {
            id: input.field_id,
            default: data.defaultValue
        };
        dispatch(setLoading(true));
        http.post(createUrl(`/field/${input.request_field_id}/update`), params).then((res) => {
            enqueueSnackbar('The input parameter has been updated', {
                action: CustomSnackbar,
                variant: 'success'
            });
            setNeedRefresh(true);
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
            dispatch(setLoading(false));
        });
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
                display: 'flex',
                marginBottom: '16px',
            }}
        >
            <FormControl
                sx={{
                    width: '100%',
                    marginRight: '10px'
                }}
            >
                <TextField
                    sx={{
                        backgroundColor: '#F2F5F8'
                    }}
                    disabled
                    error={!!errors?.name}
                    helperText={errors?.name ? 'This is a required field' : ''}
                    label="Parameter Name"
                    fullWidth
                    defaultValue={input?.name}
                    InputLabelProps={{shrink: true}}
                    {...register('name', {})}
                />
            </FormControl>

            <FormControl sx={{ width: '100%', marginRight: '10px' }}>
                <Controller
                    name="defaultValue"
                    control={control}
                    rules={{
                        required: true,
                        validate: (value) =>
                            type === 'OBJECT' ? isValidJSON(value) || 'Invalid JSON format' : true,
                    }}
                    render={({ field }) => (
                        <>
                            {type === 'OBJECT' ? (
                                <Editor
                                    {...field}
                                    value={code}
                                    id="defaultValue"
                                    onValueChange={(code) => setCode(code)}
                                    highlight={(code) => highlight(code, languages.js)}
                                    padding={10}
                                    tabSize={4}
                                    style={{
                                        borderRadius: '4px',
                                        borderColor: 'rgba(0, 0, 0, 0.23)',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        backgroundColor: '#F2F5F8',
                                        minHeight: '100px',
                                    }}
                                />
                            ) : (
                                <TextField
                                    {...field}
                                    type="text"
                                    id="defaultValue"
                                    label="Default Value"
                                    error={!!errors?.defaultValue}
                                    helperText={errors?.defaultValue ? 'This is a required field' : ''}
                                    sx={{ marginBottom: '16px' }}
                                />
                            )}
                            {errors.defaultValue && (
                                <FormHelperText>{errors.defaultValue.message}</FormHelperText>
                            )}
                        </>
                    )}
                />
            </FormControl>

            <IconButton
                sx={{
                    height: '53px',
                    width: '53px'
                }}
                type="submit"
                title="Save item"
            >
                <SaveIcon/>
            </IconButton>

            <IconButton
                sx={{
                    height: '53px',
                    width: '53px',
                    color: '#f44336'
                }}
                onClick={(e) => {
                    handleDelete(e, input);
                }}
                aria-label="delete"
                title="Delete item"
            >
                <DeleteIcon/>
            </IconButton>
        </Box>
    );
};
