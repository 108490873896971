import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { setFlow } from 'Actions/flow';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import http from 'Utils/http';
import { Transition } from 'Shared/Transition';

const FlowCreateForm = ({ visibleCreateFlowForm, setVisibleCreateFlowForm }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const onClose = () => {
        setVisibleCreateFlowForm(false);
    };

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const onSubmit = (data) => {
        const params = {
            name: data.name,
            access: 'public',
        };
        dispatch(setLoading(true));
        http.post('/flow/create', params).then((res) => {
            dispatch(setFlow(res.data.flow));
            setVisibleCreateFlowForm(false);
            navigate(`/flow/${res.data.flow.id}`);
            enqueueSnackbar('New Flow has been created', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            console.log(err, 'err');
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    return (
        <Dialog
            open={visibleCreateFlowForm}
            onClose={onClose}
            TransitionComponent={Transition}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'flex-start',
                    paddingTop: '150px',
                },
                '& .MuiDialog-paper': {
                    minWidth: '450px',
                },
            }}
        >
            <DialogTitle
                className="h1"
                variant="h1"
                sx={{
                    paddingBottom: '6px',
                    textAlign: 'center',
                    color: '#3B465C',
                }}
            >
                Add Flow
            </DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <TextField
                        error={errors?.name ? true : false}
                        helperText={errors?.name ? 'This is a required field' : ''}
                        fullWidth
                        id="name"
                        label="Flow name"
                        name="name"
                        sx={{
                            marginTop: '10px',
                        }}
                        {...register('name', {
                            required: true,
                        })}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '16px 24px',
                }}
            >
                <Button onClick={onClose} variant="outlined">
                    Close
                </Button>
                <Button onClick={handleSubmit(onSubmit)} variant="contained">
                    Add New Flow
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FlowCreateForm;
