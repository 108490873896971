import React, { useCallback, useEffect, useState } from 'react';
import http from 'Utils/http';
import { NavLink as RouterLink, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Box, AppBar, Toolbar, Typography, Container, Link } from '@mui/material';
import { logout } from 'Actions/auth';
import { ROUTES } from 'Constants/routes';
import { setLoading } from 'Actions/loading';
import { LogoutComponent } from 'Shared/LogoutComponent';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import Logo_PNG from '../../assets/img/Fintegrate_Logo__Symbol.png';

const Header = () => {
    const access = useSelector((state) => state.auth.user.access);
    const currentUser = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    // Function to remove specific search parameter and navigate
    const removeSearchParam = useCallback((key) => {
        const searchParamsCopy = new URLSearchParams(location.search);
        searchParamsCopy.delete(key);
        const newLocation = {...location, search: searchParamsCopy.toString()};
        navigate(newLocation);
    }, [location, navigate]);

    // Use useEffect to trigger actions based on search parameters
    useEffect(() => {
        const getSearchParamsWithPrefixAndPlaceholder = (prefix) => {
            searchParams.forEach((value, key) => {
                const match = key.match(new RegExp(`^${prefix}_(\\w+)$`));
                if (match) {
                    const paramName = match[1];
                    enqueueSnackbar(value, {
                        action: CustomSnackbar,
                        variant: paramName
                    });
                    removeSearchParam(key);
                }
            });
        };
        getSearchParamsWithPrefixAndPlaceholder('massage');

        // Function to handle 'massage' search parameter
        const message = searchParams.get('massage');
        if (message) {
            enqueueSnackbar(message, {
                action: CustomSnackbar,
                variant: 'info'
            });
            removeSearchParam('massage');
        }

        // Function to handle 'action' search parameter
        const action = searchParams.get('action');
        if (action === 'logout') {
            dispatch(setLoading(true));
            http.post('/logout').finally(() => {
                dispatch(logout(navigate));
                dispatch(setLoading(false));
                navigate(ROUTES.HOME);
            });
        }
    }, [searchParams, enqueueSnackbar, dispatch, navigate, removeSearchParam]);

    const [firstName, setFirstName] = useState('');

    useEffect(() => {
        if (currentUser) {
            setFirstName(currentUser.first_name);
        }
    }, [currentUser, setFirstName]);

    return (
        <AppBar position="static">
            <Container maxWidth={false}>
                <Toolbar
                    sx={{
                        '&.MuiToolbar-root': {
                            minHeight: '76px',
                            padding: '8px 0',
                        },
                    }}
                    disableGutters
                >
                    <Link
                        component={RouterLink}
                        to={ROUTES.HOME}
                        underline="none"
                        sx={{
                            marginRight: '25px',
                            marginLeft: '-20px',
                            lineHeight: 'unset',
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 60,
                                width: 60,
                            }}
                            alt="Fintegrate"
                            src={Logo_PNG}
                        />
                    </Link>

                    {!isAuthenticated && (
                        <>
                            <Typography component="div">
                                <Link
                                    className="nav-link"
                                    component={RouterLink}
                                    to={ROUTES.LOGIN}
                                    underline="none"
                                    color="primary.white"
                                    sx={{marginRight: '30px'}}
                                >
                                    LOGIN
                                </Link>
                            </Typography>

                            <Typography component="div">
                                <Link
                                    className="nav-link"
                                    component={RouterLink}
                                    to={ROUTES.REGISTRATION}
                                    underline="none"
                                    color="primary.white"
                                    sx={{marginRight: '30px'}}
                                >
                                    REGISTRATION
                                </Link>
                            </Typography>
                        </>
                    )}
                    {/* FOR ADMIN */}
                    {isAuthenticated && (
                        <>
                            <Typography component="div">
                                <Link
                                    className="nav-link"
                                    component={RouterLink}
                                    to={ROUTES.INTEGRATIONS}
                                    underline="none"
                                    color="primary.white"
                                    sx={{marginRight: '30px'}}
                                >
                                    INTEGRATIONS
                                </Link>
                            </Typography>

                            <Typography component="div">
                                <Link
                                    className="nav-link"
                                    component={RouterLink}
                                    to={ROUTES.FLOWS}
                                    underline="none"
                                    color="primary.white"
                                    sx={{marginRight: '30px'}}
                                >
                                    FLOWS
                                </Link>
                            </Typography>

                            <Typography component="div">
                                <Link
                                    className="nav-link"
                                    component={RouterLink}
                                    to={ROUTES.LOGGING}
                                    underline="none"
                                    color="primary.white"
                                    sx={{marginRight: '30px'}}
                                >
                                    LOGGING
                                </Link>
                            </Typography>
                        </>
                    )}

                    {/* FOR ADMIN */}
                    {access === 'Admin' && (
                        <>
                            <Typography component="div">
                                <Link
                                    className="nav-link"
                                    component={RouterLink}
                                    to={ROUTES.METHODS}
                                    underline="none"
                                    color="primary.white"
                                    sx={{marginRight: '30px'}}
                                >
                                    API METHODS
                                </Link>
                            </Typography>

                            <Typography component="div">
                                <Link
                                    className="nav-link"
                                    component={RouterLink}
                                    to={ROUTES.METHODS_PARAMS}
                                    underline="none"
                                    color="primary.white"
                                    sx={{marginRight: '30px'}}
                                >
                                    METHODS PARAMS
                                </Link>
                            </Typography>
                            <Typography component="div">
                                <Link
                                    className="nav-link"
                                    component={RouterLink}
                                    to={ROUTES.USERS}
                                    underline="none"
                                    color="primary.white"
                                    sx={{marginRight: '30px'}}
                                >
                                    ADMIN
                                </Link>
                            </Typography>
                        </>
                    )}

                    {access === 'User' && (
                        <Typography component="div">
                            <Link
                                className="nav-link"
                                component={RouterLink}
                                to={ROUTES.MY_ACCOUNT}
                                underline="none"
                                color="primary.white"
                                sx={{marginRight: '30px'}}
                            >
                                MY ACCOUNT
                            </Link>
                        </Typography>
                    )}

                    {isAuthenticated && (
                        <>
                            {firstName ? (<Typography
                                color="primary.white"
                                sx={{
                                    marginLeft: 'auto',
                                    fontWeight: 500,
                                    paddingLeft: '15px',
                                    paddingRight: '15px',
                                    lineHeight: '24px',
                                }}
                            >
                                Hello, {firstName}
                            </Typography>) : ''}
                            <LogoutComponent/>
                        </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
