import React, { useEffect, useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { FormControl, InputLabel, MenuItem, Select, Box, Typography } from '@mui/material';
import http from 'Utils/http';
import { useSelector } from 'react-redux';

const FlowsGraph = ({item}) => {
  const [selectedPeriod, setSelectedPeriod] = useState('365');
  const [dateRange, setDateRange] = useState('');
  const [currentDateRange, setCurrentDateRange] = useState('');
  const [totalCounter, setTotalCounter] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const apiListRedux = useSelector(state => state.APIs.APIs);
  const handleChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  useEffect(() => {
    if (item && dateRange !== currentDateRange) {
      setTotalCounter(0);
      setCurrentDateRange(dateRange);
      http.get(`/statistics/flow/${item.id}/${dateRange}`).then((res) => {
        const statistics = res.data.statistics || [];
        let defaultTotalCounter = 0;
        const defaultArray = (() => {
          const currentDate = new Date();
          const currentDayIndex = currentDate.getDay();
          const currentMonthIndex = currentDate.getMonth();

          const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

          switch (selectedPeriod) {
            case '30':
              return [...Array(30)].map((_, i) => {
                const date = new Date(currentDate);
                date.setDate(currentDate.getDate() - i);
                return { name: date.getDate() };
              }).reverse();
            case '7':
              return [...daysOfWeek.slice(currentDayIndex + 1), ...daysOfWeek.slice(0, currentDayIndex + 1)].map(name => ({ name }));
            case '365':
              return [...months.slice(currentMonthIndex + 1), ...months.slice(0, currentMonthIndex + 1)].map(name => ({ name }));
            default:
              return [];
          }
        })();

        const groupedData = statistics.sort((a, b) => new Date(a.date) - new Date(b.date)).reduce((acc, obj) => {
          const date = new Date(obj.date);
          let key;

          switch(selectedPeriod) {
            case '30':
              key = date.getDate();
              break;
            case '7':
              key = date.toLocaleDateString('en-us', { weekday: 'long' });
              break;
            case '365':
              key = date.toLocaleString('en-us', { month: 'long' });
              break;
            default:
              key = 'Today';
          }

          const existingEntry = acc.find(item => item.name === key) || { name: key };

          const integration = apiListRedux.find(api => api.id === obj.integration_id);

          defaultTotalCounter += obj.counter;

          if (!existingEntry?.items) {
            existingEntry.items = []
          }

          const findObjItem = existingEntry.items.find(item => item.id === obj.integration_id);
          if (findObjItem) {
            findObjItem.counter += obj.counter;
          } else {
            existingEntry.items.push({
              id: obj.integration_id,
              name: integration.name,
              counter: obj.counter
            });
          }
          if (!acc.includes(existingEntry)) {
            acc.push(existingEntry);
          }
          return acc;
        }, defaultArray);
        groupedData.forEach(data => {
          if (data?.items) {
            data.items.forEach(({name, counter}) => {
              data[name] = counter;
            });
            delete data.items;
          }
        });
        const uniqueKeys = [...new Set(groupedData.flatMap(item => Object.keys(item)))].filter(word => word !== 'name');
        setSelectedKeys(uniqueKeys);
        setSelectedData(groupedData);
        setTotalCounter(defaultTotalCounter);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [item, selectedPeriod, dateRange, setSelectedData, setCurrentDateRange, apiListRedux, currentDateRange]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    // Add leading zeros if needed
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const getDateRange = (period) => {
      const today = new Date();
      let startDate, endDate;

      switch (period) {
        case '1':
          return { date: formatDate(today) };
        case '7':
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 7);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date(today);
          endDate.setHours(23, 59, 59, 999);
          break;
        case '30':
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 30);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date(today);
          endDate.setHours(23, 59, 59, 999);
          break;
        case '365':
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 365);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date(today);
          endDate.setHours(23, 59, 59, 999);
          break;
        default:
          return { date: formatDate(today) };
      }

      return {
        date: `${formatDate(startDate)}...${formatDate(endDate)}`,
      }
    };
    const range = getDateRange(selectedPeriod);
    setDateRange(range.date);
  }, [selectedPeriod]);

  const getColor = useMemo(() => {
    return (key, id) => {
      const api = apiListRedux.find(item => item.name === key);
      const colors = ['#F4B156', '#E07B6A', '#c191ff', '#2FDE8A', '#FF5733', '#1fa3d2', '#00CED1', '#FFD700', '#7CFC00', '#FF6347', '#8B4513', '#00FA9A', '#FF69B4', '#4169E1', '#ad4cc5', '#FF4500', '#20B2AA', '#FF8C00', '#9932CC', '#00BFFF', '#48D1CC', '#FFDAB9', '#DDA0DD', '#40E0D0'];
      return colors[api?.id % colors.length];
    };
  }, [apiListRedux]);

  return (
    <Box
      sx={{
        padding: '20px',
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '24px'}}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Period</InputLabel>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name='period'
              value={selectedPeriod}
              label="Period"
              onChange={handleChange}
          >
            <MenuItem value='1'>Today</MenuItem>
            <MenuItem value='7'>Last 7 days</MenuItem>
            <MenuItem value='30'>Last 30 days</MenuItem>
            <MenuItem value='365'>Last 12 months</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h6"
            sx={{
              color: '#3B465C',
              marginLeft: '20px',
              flexShrink: 0,
            }}
        >
          Requests Total:
          {totalCounter}
        </Typography>
      </Box>
      <Box
        sx={{
          marginLeft: '-34px',
          '& .recharts-legend-item-text': {
            fontFamily: 'Roboto',
          },
          '& .recharts-text': {
            fontFamily: 'Roboto',
          },
          '& .recharts-tooltip-wrapper': {
            fontFamily: 'Roboto',
          },
          '& .recharts-legend-item': {
            marginRight: '40px !important',
          },
          '& .recharts-legend-wrapper': {
            left: '35px !important',
          },
        }}
      >
        <ResponsiveContainer width="100%" height={450}>
          <BarChart data={selectedData}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip/>
            <Legend
              verticalAlign="bottom"
              align="center"
              iconSize={10}
              iconType="square"
            />
            { selectedKeys.map((item, index) => (<Bar key={index} dataKey={item} stackId="a" fill={getColor(item)} />)) }
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default FlowsGraph;
