import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallback } from "react";


export function useCreateUrl() {
  const access = useSelector((state) => state.auth.user.access);
  const user_id = useSelector((state) => state.auth.user.id);
  const { current_user_id } = useParams();

  const createUrl = useCallback((url) => {
    if (access === 'Admin' && current_user_id) {
      url = `${url}?user_id=${current_user_id}`;
      return url;
    }

    if (access === 'Admin' && user_id) {
      url = `${url}?user_id=${user_id}`;
    }
    return url;
  }, [access, current_user_id, user_id]);

  return { createUrl };
}
