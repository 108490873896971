import { SET_CONTENT_MATCH_MODAL, SET_VISIBLE_MATCH_MODAL } from '../types';

const initialState = {
  visible: false,
  content: {
    api: {
      id: null,
      name: null,
    },
    method: {
      id: null,
      name: null,
      request: [],
      response: [],
    },
    title: null,
  },
};

const matchModal = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTENT_MATCH_MODAL:
      return {
        ...state,
        content: action.payload,
      };
    case SET_VISIBLE_MATCH_MODAL:
      return {
        ...state,
        visible: action.payload,
      };
    default:
      return state;
  }
};

export default matchModal;
