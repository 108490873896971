import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { Box, Button, Typography, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import http from 'Utils/http';

export const SaveToFileForm = ({operationId}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const flow_id = useSelector((state) => state.flow.flow?.id);
    const [outputPortLastOrder, setOutputPortLastOrder] = useState([]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ mode: 'onChange' });

    useEffect(() => {
        if (flow_id) {
            http.get(`/output/actions/${flow_id}`).then((res) => {
                const sortByOrder = res.data.actions.sort((a, b) => (a.order > b.order ? 1 : -1));
                const needItem = sortByOrder.find((item) => {
                    return item.operation_id === operationId;
                });
                setOutputPortLastOrder(sortByOrder[sortByOrder.length - 1]);
                if (needItem) {
                    setValue('fileNames', sortByOrder[sortByOrder.length - 1].modificator);
                }
            }).catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {
                    action: CustomSnackbar,
                    variant: 'error'
                });
            });
        }
    }, [flow_id, enqueueSnackbar, operationId, setValue]);

    const onSubmit = (data) => {
        if (outputPortLastOrder) {
            dispatch(setLoading(true));

            const params = {
                id: outputPortLastOrder.id,
                modificator: data.fileNames,
                operation_id: operationId,
            };

            http.post(`/action/${outputPortLastOrder.id}/update`, params).then(() => {
                enqueueSnackbar('Your modification has been saved', {
                    action: CustomSnackbar,
                    variant: 'success'
                });
            }).catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {
                    action: CustomSnackbar,
                    variant: 'error'
                });
            }).finally(() => {
                dispatch(setLoading(false));
            });
        }

        if (!outputPortLastOrder) {
            dispatch(setLoading(true));

            const params = {
                flow_id: flow_id,
                modificator: data.fileNames,
                operation_id: operationId,
                type: 'for_output_port',
            };

            http.post('/action/create', params).then(() => {
                enqueueSnackbar('Your modification has been saved', {
                    action: CustomSnackbar,
                    variant: 'success'
                });
            }).catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {
                    action: CustomSnackbar,
                    variant: 'error'
                });
            }).finally(() => {
                dispatch(setLoading(false));
            });
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Typography
                sx={{
                    marginBottom: '16px',
                    fontWeight: '700',
                    letterSpacing: '0.1em',
                    textTransform: 'uppercase',
                    color: 'rgba(65, 77, 101, 0.85)',
                }}
            >
                File Name
            </Typography>

            <TextField
                label="Example: param_file"
                readOnly
                fullWidth
                sx={{
                    marginRight: '10px',
                    marginBottom: '20px',
                    backgroundColor: '#F2F5F8',
                }}
                error={!!errors?.fileNames}
                helperText={errors?.fileNames ? 'This is a required field' : ''}
                InputLabelProps={{shrink: true}}
                {...register('fileNames', {
                    required: true,
                })}
            />
            <Button sx={{marginBottom: '20px'}} variant="contained" type="submit">
                Save
            </Button>
        </Box>
    );
};
