import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <Box className="spinner-wrapper">
      <CircularProgress
        sx={{
          color: 'primary.white',
        }}
      />
    </Box>
  );
};

export default Loader;
