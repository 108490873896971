import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const TagItem = ({text, color}) => {
    return (
        <Box
            sx={{
                marginRight: '10px',
                marginBottom: '10px',
                padding: '2px 10px',
                background: `${color}`,
                borderRadius: '5px',
            }}
        >
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '10px',
                    lineHeight: '12px',
                    color: '#3B465C',
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};
