import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from 'Actions/auth';
import { Grid, Box, Link, Typography, Button } from '@mui/material';

const  ErrorBoundryComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout(navigate));
    document.location.reload();
  };

  const navigateToMainPage = (e) => {
    e.preventDefault();
    document.location.href = '/';
    // onClick={logoutHandler}
  };

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flex: '1',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <Grid
        item
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            marginTop: '100px',
            marginBottom: '12px',
            fontSize: '2.5rem',
            fontWeight: 500,
            color: 'white',
          }}
        >
          Something went wrong
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="center">
          <Link
            component={RouterLink}
            to="/"
            onClick={navigateToMainPage}
            sx={{
              fontSize: '2.5rem',
              fontWeight: 400,
              marginRight: '12px',
            }}
          >
            Return to main
          </Link>

          <Button
            onClick={logoutHandler}
            className="dark"
            variant="contained"
            sx={{
              padding: '8px 16px',
              fontSize: '16px',
              borderRadius: '4px',
            }}
          >
            Logout
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default ErrorBoundryComponent;
