import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField, Button } from '@mui/material';
import { AddIcon } from 'Shared/Icons';
import { AddMethodsModal } from 'Components/methods/AddMethodsModal';

const MethodsSearchForm = ({ methodsList, setFilteredMethodsList, setPage, setNeedRefresh }) => {
  const [addMethodsModalVisible, setAddMethodsModalVisible] = useState(false);

  const {
    register,
    handleSubmit,
    setValue
  } = useForm();

  const onSubmit = (data) => {
    searchHandler(data);
  };

  const clearHandler = () => {
    setValue('integration_name', '');
    setValue('method_name', '');
    setPage(0);
    setFilteredMethodsList(methodsList);
  };

  const searchHandler = (data) => {
    let filteredList = methodsList;

    if (data.integration_name) {
      filteredList = methodsList.filter((item, index) => {
        if (!item[`integration_name`]) {
          return false;
        }
        return (
          item[`integration_name`].toString().toLowerCase().indexOf(data.integration_name.toLowerCase().trim()) !== -1
        );
      });
    }

    if (data.method_name) {
      filteredList = filteredList.filter((item, index) => {
        if (!item[`name`]) {
          return false;
        }
        return item[`name`].toString().toLowerCase().indexOf(data.method_name.toLowerCase().trim()) !== -1;
      });
    }

    setPage(0);
    setFilteredMethodsList(filteredList);
  };

  return (
    <>
      <AddMethodsModal
        visible={addMethodsModalVisible}
        onClose={() => {
          setAddMethodsModalVisible(false);
        }}
        setNeedRefresh={setNeedRefresh}
      />
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextField
          label="Search by Integration Name"
          size="small"
          sx={{
            marginRight: '16px',
          }}
          {...register('integration_name', {})}
        />
        <TextField
          label="Search by Method Name"
          size="small"
          sx={{
            marginRight: '16px',
          }}
          {...register('method_name', {})}
        />

        <Button
          sx={{
            marginRight: '16px',
          }}
          onClick={searchHandler}
          variant="contained"
          type="submit"
        >
          Search
        </Button>
        <Button sx={{ marginRight: '16px' }} onClick={clearHandler} variant="outlined">
          Clear
        </Button>

        <Button
          sx={{ marginLeft: 'auto' }}
          onClick={() => {
            setAddMethodsModalVisible(true);
          }}
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Add Method
        </Button>
      </Box>
    </>
  );
};

export default MethodsSearchForm;
