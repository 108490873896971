import React, { useState, useEffect } from 'react';
import http from 'Utils/http';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'Actions/loading';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography,
    TablePagination,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { openFileIntoNewTab } from 'Utils/utils';
import LoggingSearchForm from 'Components/logging/LoggingSearchForm';

const LoggingPage = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const access = useSelector((state) => state.auth.user.access);
    const user_id = useSelector((state) => state.auth.user.id);

    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [totalCalls, setTotalCalls] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState({});

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [statisticsList, setStatisticsList] = useState([]);
    const [filteredStatisticsList, setFilteredStatisticsList] = useState([]);

    useEffect(() => {
        const url = '/statistics/aggregated';
        dispatch(setLoading(true));

        const params = {
            pagination: {
                pageSize: rowsPerPage,
                currentPage: page
            },
            user_id: user_id,
            filter: filter
        };

        const jsonStringPagination = JSON.stringify(params.pagination);
        const encodedStringPagination = encodeURIComponent(jsonStringPagination);

        const jsonStringUserId = JSON.stringify(params.user_id);
        const encodedStringUserId = encodeURIComponent(jsonStringUserId);

        const jsonStringFilter = JSON.stringify(params.filter);
        const encodedStringFilter = encodeURIComponent(jsonStringFilter);

        const fullUrl = `${url}?user_id=${encodedStringUserId}&pagination=${encodedStringPagination}&filter=${encodedStringFilter}`;

        http.get(fullUrl).then((res) => {
            const { records, total_calls, pagination } = res.data;
            const { totalRecords } = pagination;
            const statistics = records || [];
            const totalCalls = total_calls || 0;
            const pages = totalRecords || 1;
            setPages(pages);
            setTotalCalls(totalCalls);
            setStatisticsList(statistics);
            setFilteredStatisticsList(statistics);
            dispatch(setLoading(false));
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {action: CustomSnackbar, variant: 'error'});
            dispatch(setLoading(false));
        });
    }, [access, dispatch, enqueueSnackbar, user_id, page, rowsPerPage, filter]);

    const downloadLogs = (item) => {
        dispatch(setLoading(true));
        http.get(`/statistics/get/${item.date}`).then((res) => {
            openFileIntoNewTab(res.data.logs, `logs_${item.date}.txt`);
            dispatch(setLoading(false));
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {action: CustomSnackbar, variant: 'error'});
        });
    };

    return (
        <>
            <Typography
                variant="h1"
                sx={{
                    textAlign: 'center',
                    marginBottom: '25px',
                    fontSize: '3rem',
                    fontWeight: 500,
                }}
            >
                Logging
            </Typography>
            <Box component={Paper} sx={{padding: '20px 20px 0px 20px'}}>
                <Box sx={{
                    marginBottom: '20px',
                }}>
                    <LoggingSearchForm
                        statisticsList={statisticsList}
                        setFilteredStatisticsList={setFilteredStatisticsList}
                        setPage={setPage}
                        setFilter={setFilter}
                        totalCalls={totalCalls}
                    />
                </Box>

                <TableContainer>
                    <Table sx={{minWidth: 650}} size="medium">
                        <TableHead>
                            <TableRow
                                sx={{
                                    height: '50px',
                                    '& .MuiTableCell-root': {
                                        fontSize: '1.2rem',
                                    },
                                }}
                            >
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">API</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Calls</TableCell>
                                <TableCell align="center">Tools</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredStatisticsList.map((item, index) => {
                                    const {
                                        user_email = '-',
                                        api = '-',
                                        date = '-',
                                        calls = '-',
                                    } = item;

                                    const key = `${user_email}_${api}_${date}`;

                                    return (
                                        <TableRow
                                            key={key}
                                            sx={{
                                                '& .MuiTableCell-root': {
                                                    fontSize: '1.2rem',
                                                },
                                                '&:last-child td, &:last-child th': {border: 0},
                                            }}
                                        >
                                            <TableCell align="center">{user_email}</TableCell>
                                            <TableCell align="center">{api}</TableCell>
                                            <TableCell align="center">{date}</TableCell>
                                            <TableCell align="center">{calls}</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => {
                                                        downloadLogs(item);
                                                    }}
                                                >
                                                    Details
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>

                    {!filteredStatisticsList.length && (
                        <Typography
                            sx={{
                                marginTop: '24px',
                                marginBottom: '24px',
                                textAlign: 'center',
                                fontSize: '2rem',
                                fontWeight: 500,
                                color: '#D76A6A',
                            }}
                        >
                            Items Not found
                        </Typography>
                    )}
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={pages}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </>
    );
};

export default LoggingPage;
